import React, { useReducer, useContext } from "react";
import MetalOrderContext from "./metalOrderContext";
import metalOrderReducer from "./metalOrderReducer";
import axios from "axios";
import emailjs from "@emailjs/browser";
import AuthContext from "../auth/authContext";

import { toast } from "react-toastify";

import {
  ADD_METALORDER,
  DELETE_METALORDER,
  CLEAR_METALORDERS,
  SET_CURRENT_METALORDER,
  CLEAR_CURRENT_METALORDER,
  UPDATE_METALORDER,
  FILTER_METALORDERS,
  CLEAR_FILTER_METALORDER,
  METALORDER_ERROR,
  GET_METALORDERS,
  SET_LOADING_METALORDER,
} from "../types";

const MetalOrderState = (props) => {
  const initialState = {
    metalOrders: null,
    currentMetalOrder: null,
    filteredMetalOrder: null,
    loadingMetalOrder: false,
    error: null,
  };

  const [state, dispatch] = useReducer(metalOrderReducer, initialState);

  // get current user
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  // get metal Orders
  const getMetalOrders = async () => {
    try {
      const res = await axios.get("/api/metalorder");
      dispatch({ type: GET_METALORDERS, payload: res.data });
    } catch (err) {
      dispatch({ type: METALORDER_ERROR });
    }
  };

  const getMetalOrdersByStatus = async (status) => {
    try {
      const res = await axios.get(`/api/metalorder/${status}`);
      dispatch({ type: GET_METALORDERS, payload: res.data });
    } catch (err) {
      dispatch({ type: METALORDER_ERROR });
    }
  };

  // add metal Order
  const addMetalOrder = async (metalOrders) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      setLoadingMetalOrder(true);
      const res = await axios.post("/api/metalorder", { metalOrders }, config);
      dispatch({ type: ADD_METALORDER, payload: res.data });
      console.log("this is the res.data", res.data);
      dispatch({ type: SET_CURRENT_METALORDER, payload: res.data });
      if (user.firstChoiceCustomer) {
        // console.log("this is the user", user);
        if (user.strWarehouseID === "FCM - ADEL") {
          console.log("the email went to ADEL");
          emailjs.send("service_1am6s71", "template_4bjuxps", res.data, "UCnTfq1BhUxk1M1nn").then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
        } else {
          console.log("the email went to NEW BOSTON");
          emailjs.send("service_1am6s71", "template_u7bw7xl", res.data, "UCnTfq1BhUxk1M1nn").then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
        }
      }

      toast.success(`Metal Order is created`);
      setLoadingMetalOrder(false);
      return res.data;
    } catch (err) {
      dispatch({ type: METALORDER_ERROR });

      // Display the error message
      toast.error(`Metal Order: ${err.response.data.message}`);
      setLoadingMetalOrder(false);

      throw err; // Add this line
    }
  };

  // delete metal Order
  const deleteMetalOrder = async (metalOrder) => {
    try {
      const res = await axios.delete(`/api/metalorder/delete/${metalOrder._id}`);
      dispatch({ type: DELETE_METALORDER, payload: metalOrder._id });
      toast.success(`Metal Order is deleted`);
    } catch (err) {
      dispatch({ type: METALORDER_ERROR });
      toast.error(`Metal Order ${err}`);
    }
  };

  // update metal Order
  const updateMetalOrder = async (metalOrder) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      setLoadingMetalOrder(true);
      const res = await axios.put(`/api/metalorder/update/${metalOrder._id}`, metalOrder, config);
      dispatch({ type: UPDATE_METALORDER, payload: res.data });
      dispatch({ type: SET_CURRENT_METALORDER, payload: res.data });
      setLoadingMetalOrder(false);
      toast.success(`Metal Order is updated`);
    } catch (err) {
      dispatch({ type: METALORDER_ERROR });
      toast.error(`Metal Order ${err}`);
      setLoadingMetalOrder(false);
    }
  };

  const clearMetalOrders = () => {
    dispatch({ type: CLEAR_METALORDERS });
  };
  const setCurrentMetalOrder = (metalOrder) => {
    dispatch({ type: SET_CURRENT_METALORDER, payload: metalOrder });
  };
  const clearCurrentMetalOrder = () => {
    dispatch({ type: CLEAR_CURRENT_METALORDER });
  };
  const filterMetalOrders = (text) => {
    dispatch({ type: FILTER_METALORDERS, payload: text });
  };
  const clearFilterMetalOrder = () => {
    dispatch({ type: CLEAR_FILTER_METALORDER });
  };

  // set loading to bool
  const setLoadingMetalOrder = (bool) => {
    dispatch({ type: SET_LOADING_METALORDER, payload: bool });
  };

  return (
    <MetalOrderContext.Provider
      value={{
        metalOrders: state.metalOrders,
        currentMetalOrder: state.currentMetalOrder,
        filteredMetalOrder: state.filteredMetalOrder,
        error: state.error,
        loadingMetalOrder: state.loadingMetalOrder,
        getMetalOrders,
        addMetalOrder,
        deleteMetalOrder,
        setCurrentMetalOrder,
        clearCurrentMetalOrder,
        updateMetalOrder,
        filterMetalOrders,
        clearFilterMetalOrder,
        clearMetalOrders,
        setLoadingMetalOrder,
        getMetalOrdersByStatus,
      }}
    >
      {props.children}
    </MetalOrderContext.Provider>
  );
};

export default MetalOrderState;
