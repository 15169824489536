import React, { useReducer, useEffect, useContext } from "react";
import axios from "axios";
import AuthContext from "./authContext";
import authReducer from "./authReducer";
import setAuthToken from "../../utils/SetAuthToken";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  SET_LOADING_USER,
  LOAD_USERS,
  SET_CURRENT_USER,
  CLEAR_CURRENT_USER,
} from "../types";

const AuthState = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);
  const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    loading: true,
    user: null,
    error: null,
    users: null,
    currentUser: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  // load user
  const loadUser = async () => {
    // load token into global headers
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    } else {
      setAuthToken(null); // reset axios headers
    }
    try {
      const res = await axios.get("/api/auth");
      dispatch({ type: USER_LOADED, payload: res.data });
      if (res.data.role === "Office Admin") {
        //   navigate("/company/home");
        // } else {
        //   navigate("/shop/home");
      }
    } catch (err) {
      dispatch({ type: AUTH_ERROR });
    }
  };

  // // load all users
  const getUsers = async () => {
    try {
      const res = await axios.get(`/api/users/`);
      dispatch({ type: LOAD_USERS, payload: res.data });
    } catch (err) {
      dispatch({ type: AUTH_ERROR });
    }
  };

  // const copyEverything = async (newUserId) => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   try {
  //     const res = await axios.post("/api/users/copyeverything/", { newUserId }, config);
  //     toast.success(`Copy was successful`);
  //   } catch (err) {
  //     dispatch({ type: AUTH_ERROR });
  //   }
  // };

  // addUser user
  const addUser = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/users", formData, config);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
      loadUser();
    } catch (err) {
      dispatch({
        type: REGISTER_FAIL,
        payload: err.response.data.msg,
      });
    }
  };

  const addNewUser = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/users/new/user", formData, config);
    } catch (err) {
      dispatch({
        type: REGISTER_FAIL,
        payload: err.response.data.msg,
      });
    }
  };

  const updateUser = async (id, formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      console.log("This is updateUser");
      const res = await axios.put(`/api/users/update/${id}`, formData, config);
      toast.success(`User was updated successfully`);
    } catch (err) {
      dispatch({
        type: REGISTER_FAIL,
        payload: err.response.data.msg,
      });
    }
  };

  const addCompanyUser = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/users/company/add", formData, config);
    } catch (err) {
      dispatch({
        type: REGISTER_FAIL,
        payload: err.response.data.msg,
      });
    }
  };

  // login user
  const login = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/auth", formData, config);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      loadUser();
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data.msg,
      });
    }
  };

  // logout user
  const logout = () => {
    dispatch({ type: LOGOUT });
  };

  // clear errors
  const clearErrors = () => {
    dispatch({ type: CLEAR_ERRORS });
  };

  const setLoadingUser = (bool) => {
    dispatch({ type: SET_LOADING_USER, payload: bool });
  };

  const setCurrentUser = (user) => {
    dispatch({ type: SET_CURRENT_USER, payload: user });
  };
  const clearCurrentUser = (user) => {
    dispatch({ type: CLEAR_CURRENT_USER, payload: user });
  };

  const deleteUser = async (id) => {
    try {
      const res = await axios.delete(`/api/users/user/delete/${id}`);
      // dispatch({ type: DELETE_USER, payload: id });
    } catch (err) {
      // dispatch({ type: USER_ERROR });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        currentUser: state.currentUser,
        users: state.users,
        error: state.error,
        addUser,
        login,
        loadUser,
        logout,
        clearErrors,
        getUsers,
        // copyEverything,
        setLoadingUser,
        addNewUser,
        setCurrentUser,
        clearCurrentUser,
        addCompanyUser,
        updateUser,
        deleteUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
