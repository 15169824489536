import Spinner from "../components/layout/Spinner";
import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const ProtectedRoutes = ({ children, roleRequired }) => {
  const { user, loading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      if (!user) {
        // Redirect user to login page if they're not authenticated
        navigate("/login");
      } else if (roleRequired && roleRequired !== user.role && location.pathname !== "/denied") {
        navigate("/denied");
      }
    }
  }, [user, navigate, location.pathname, roleRequired, loading]);

  if (loading) {
    return <Spinner />;
  }

  if (!user) {
    navigate("/login");
    return null;
  }

  return <Outlet>{children}</Outlet>;
};

export default ProtectedRoutes;
