import React, { useState, useContext, useEffect } from "react";
import SettingsContext from "../../../../../../context/settings/settingsContext";
import { useNavigate } from "react-router-dom";
const initialState = {
  sidingColor: { color: "" },
};

const AddSidingColor = ({ selectedSidingColor, setSelectedSidingColor }) => {
  const navigate = useNavigate();

  const settingsContext = useContext(SettingsContext);
  const { settings, getSettings, updateSettings } = settingsContext;

  const [values, setValues] = useState(initialState);
  // destructure
  const { sidingColor } = values;
  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (selectedSidingColor) {
      setValues({
        sidingColor: selectedSidingColor,
      });
    }
  }, [selectedSidingColor]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Make a copy of the settings's sidingColors array
    let updatedSidingColors = [...settings.sidingColors];
    // If a color is selected, update it
    if (selectedSidingColor) {
      const oldColorId = selectedSidingColor._id; // the old color's _id
      const newColor = values.sidingColor.color; // the new color
      // Find the index of the old color
      let index = updatedSidingColors.findIndex((color) => color._id === oldColorId);
      // Replace the old color with the new color
      if (index !== -1) {
        updatedSidingColors[index].color = newColor;
      }
    } else {
      // If no color is selected, add a new one
      const newColor = values.sidingColor.color; // the new color
      updatedSidingColors.push({ color: newColor });
    }
    // Update the company
    let updatedSettings = { ...settings, sidingColors: updatedSidingColors };
    updateSettings(updatedSettings);
    // Reset the form values
    setSelectedSidingColor(null);
    setValues(initialState);
  };

  const handleChange = (e) => {
    const [field, nestedField, nestedNestedField] = e.target.name.split(".");
    setValues((prevValues) => {
      const newValues = {
        ...prevValues,
        [field]: {
          ...prevValues[field],
          [nestedField]: {
            ...prevValues[field][nestedField],
            [nestedNestedField]: e.target.value,
          },
        },
      };
      return newValues;
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h4 style={{ textAlign: "center" }}>Siding Color</h4>

      <div className="form-group">
        <label>Color</label>
        <input
          type="string"
          name="sidingColor.color.name"
          className="form-control inputbg"
          value={sidingColor.color.name}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Code</label>
        <input
          type="string"
          name="sidingColor.color.code"
          className="form-control inputbg"
          value={sidingColor.color.code}
          onChange={handleChange}
        />
      </div>
      <button className="btn btn-prime float-end mb-2 mt-4">Save Siding Color</button>
    </form>
  );
};

export default AddSidingColor;
