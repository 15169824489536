import React, { useEffect, useContext } from "react";
import ItemContext from "../../../../../context/item/itemContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Checkbox } from "antd";

const defaultValues = {};
const ItemForm = () => {
  const navigate = useNavigate();
  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const itemContext = useContext(ItemContext);
  const { updateItem, currentItem, clearCurrentItem, clearFilterItem } = itemContext;

  useEffect(() => {
    currentItem && reset(currentItem);
  }, []);

  const onSubmit = (data) => {
    updateItem(data);
    navigate(-1);
    clearCurrentItem();
  };

  const setExit = () => {
    navigate(-1);
    // clearCurrentDoor();
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
            Back
          </button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <h4 className="text-center">Update Item</h4>
        </div>
      </div>
      <div className="row">
        <div className=" simpleform">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className=" " style={{ borderRadius: "5px" }}>
              <div className="col-md-12">
                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>Name</label>
                    <input className="form-control inputbg" name="name" {...register("name", { required: true })} />
                  </div>
                  <div className="form-group">
                    <label>Product ID</label>
                    <input
                      className="form-control inputbg"
                      name="strProductID"
                      {...register("strProductID", { required: true })}
                    />
                  </div>
                </div>

                <button className="btn btn-prime float-end " type="submit">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ItemForm;
