import React, { useReducer } from "react";
import ItemContext from "./itemContext";
import itemReducer from "./itemReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_ITEM,
  DELETE_ITEM,
  CLEAR_ITEMS,
  SET_CURRENT_ITEM,
  CLEAR_CURRENT_ITEM,
  UPDATE_ITEM,
  FILTER_ITEMS,
  CLEAR_FILTER_ITEM,
  ITEM_ERROR,
  GET_ITEMS,
  SET_LOADING_ITEM,
  GET_STANDARD_ITEMS,
  GET_ALL_ITEMS,
} from "../types";

const ItemState = (props) => {
  const initialState = {
    items: null,
    allItems: null,
    standardItems: null,
    currentItem: null,
    filteredItems: null,
    loadingItem: false,
    error: null,
  };

  const [state, dispatch] = useReducer(itemReducer, initialState);

  // get all Items
  const getItems = async () => {
    try {
      const res = await axios.get(`/api/item/`);
      dispatch({ type: GET_ALL_ITEMS, payload: res.data });
    } catch (err) {
      dispatch({ type: ITEM_ERROR });
    }
  };

  // const getAllItems = async (id) => {
  //   try {
  //     const res = await axios.get(`/api/item/shop/all/${id}`);
  //     dispatch({ type: GET_ITEMS, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: ITEM_ERROR });
  //   }
  // };

  // const getAllStandardItems = async () => {
  //   try {
  //     const res = await axios.get(`/api/item/standard/all`);
  //     dispatch({ type: GET_STANDARD_ITEMS, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: ITEM_ERROR });
  //   }
  // };

  // get Items by category
  const getItemsByCategory = async (itemcategory) => {
    try {
      const res = await axios.get(`/api/item/${itemcategory}`, {
        // params: {
        //   category: category,
        // },
      });
      dispatch({ type: GET_ITEMS, payload: res.data });
    } catch (err) {
      dispatch({ type: ITEM_ERROR });
    }
  };

  const addItem = async (item) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/item/", { item }, config);
      dispatch({ type: ADD_ITEM, payload: res.data });
    } catch (err) {
      dispatch({ type: ITEM_ERROR });
    }
  };

  // const updateAllShops = async (shops, file) => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   try {
  //     const res = await axios.put(`/api/item/shops/update/all`, { shops, file }, config);
  //     toast.success(`Standard Items values are updated in selected Shops`);
  //   } catch (err) {
  //     dispatch({ type: ITEM_ERROR });
  //     toast.error(`Items are not updated`);
  //   }
  // };

  const updateShopItems = async (file) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/item/shop/update/all`, { file }, config);
      toast.success(`Items values are updated`);
    } catch (err) {
      dispatch({ type: ITEM_ERROR });
      toast.error(`Items are not updated`);
    }
  };

  const deleteItem = async (id) => {
    try {
      const res = await axios.delete(`/api/item/delete/${id}`);
      dispatch({ type: DELETE_ITEM, payload: id });
    } catch (err) {
      dispatch({ type: ITEM_ERROR });
    }
  };

  // update Item
  const updateItem = async (item) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/item/update/${item._id}`, item, config);
      dispatch({ type: UPDATE_ITEM, payload: item });
      toast.success(`Item is updated`);
    } catch (err) {
      dispatch({ type: ITEM_ERROR });
    }
  };

  const clearItems = () => {
    setLoadingItem(true);
    dispatch({ type: CLEAR_ITEMS });
  };

  const setCurrentItem = (item) => {
    dispatch({ type: SET_CURRENT_ITEM, payload: item });
  };

  const clearCurrentItem = () => {
    dispatch({ type: CLEAR_CURRENT_ITEM });
  };

  const filterItems = (text) => {
    dispatch({ type: FILTER_ITEMS, payload: text });
  };

  const clearFilterItem = () => {
    dispatch({ type: CLEAR_FILTER_ITEM });
  };

  const setLoadingItem = (bool) => {
    dispatch({ type: SET_LOADING_ITEM, payload: bool });
  };

  return (
    <ItemContext.Provider
      value={{
        items: state.items,
        allItems: state.allItems,
        standardItems: state.standardItems,
        currentItem: state.currentItem,
        filteredItems: state.filteredItems,
        loadingItem: state.loadingItem,
        error: state.error,
        getItems,
        // getAllItems,
        // getAllStandardItems,
        getItemsByCategory,
        addItem,
        deleteItem,
        setCurrentItem,
        clearCurrentItem,
        updateItem,
        filterItems,
        clearFilterItem,
        clearItems,
        setLoadingItem,
        // updateAllShops,
        updateShopItems,
      }}
    >
      {props.children}
    </ItemContext.Provider>
  );
};

export default ItemState;
