import React, { Fragment, useEffect, useContext } from "react";
import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import MetalOrderContext from "../../../../../context/metalOrder/metalOrderContext";
import PendingOrders from "./PendingOrders";
import RecievedOrders from "./RecievedOrders";
import ConfirmedOrders from "./ConfirmedOrders";
import AllOrders from "./AllOrders";

const MetalPurchaseOrders = () => {
  const navigate = useNavigate();
  const metalOrderContext = useContext(MetalOrderContext);
  const { clearCurrentMetalOrder, clearMetalOrders, getMetalOrdersByStatus, metalOrders, getMetalOrders } = metalOrderContext;

  const handleAddNewMetalOrder = (e) => {
    clearCurrentMetalOrder();
    e.preventDefault();
    navigate("/shop/purchase/metal/form");
  };

  const handleExit = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    getMetalOrdersByStatus("Pending");
  }, []);

  useEffect(() => {}, [metalOrders]);

  const onChange = (key) => {
    console.log(key);
    clearMetalOrders();
    // get metal orders by status
    if (key === "1") {
      getMetalOrdersByStatus("Pending");
    }
    if (key === "2") {
      getMetalOrdersByStatus("Confirmed");
    }
    if (key === "3") {
      getMetalOrdersByStatus("Recieved");
    }
    if (key === "4") {
      getMetalOrders();
    }
  };
  const items = [
    {
      key: "1",
      label: "Pending Orders",
      children: <PendingOrders metalOrders={metalOrders} />,
    },
    {
      key: "2",
      label: "Confirmed Orders",
      children: <ConfirmedOrders metalOrders={metalOrders} />,
    },
    {
      key: "3",
      label: "Recieved Orders",
      children: <RecievedOrders metalOrders={metalOrders} />,
    },
    {
      key: "4",
      label: "All Orders",
      children: <AllOrders metalOrders={metalOrders} />,
    },
  ];

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12"></div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <button className="btn btn-prime float-start mb-4" type="button" onClick={(e) => handleAddNewMetalOrder(e)}>
                Create New Order
              </button>

              <button className="btn btn-outline-danger float-end mb-4" type="button" onClick={(e) => handleExit(e)}>
                Back
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MetalPurchaseOrders;
