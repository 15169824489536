import React, { Fragment, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import UserNav from "./components/layout/nav/UserNav";
import { ToastContainer } from "react-toastify";
import { Layout } from "antd";
import AuthContext from "./context/auth/authContext";
import Spinner from "./components/layout/Spinner";

import Alerts from "./components/layout/Alerts";
import AnnouncementAlert from "./components/pages/shop/announcements/AnnouncementAlert";
import packageInfo from "../package.json";
import UpdateApp from "./UpdateApp";

const { Content, Sider, Footer } = Layout;

const MyLayout = () => {
  const authContext = useContext(AuthContext);
  const { user, loading } = authContext;
  const navigate = useNavigate();

  // i need a useEffect here to get the settings
  useEffect(() => {
    if (loading) {
      return <Spinner />;
    } else if (!loading && !user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const startDate = new Date("2024-05-07"); // Example start date
  const targetDate = new Date(startDate);
  targetDate.setDate(targetDate.getDate() + 30); // Set the end date to 30 days after the start date

  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 86400000); // Updates every day

    return () => clearInterval(timer); // Cleanup on unmount
  }, []);

  const remainingDays = Math.ceil((targetDate - currentDate) / (1000 * 60 * 60 * 24));

  return (
    <Fragment>
      <Layout style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <Navbar />

        <Layout style={{ flex: 1, overflow: "auto" }}>
          <Sider width={200} style={{ backgroundColor: "#EBEBEB" }} className="site-layout-background" theme="light">
            <UserNav />
          </Sider>

          <Layout style={{ padding: "0 24px 24px" }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                overflow: "auto",
              }}
            >
              <Alerts />
              <div className="row" style={{ backgroundColor: "yellow", padding: "10px", textAlign: "center" }}>
                Please use the new version of Choice-Link by using the provided link. Your Login information should remain the
                same. This version will be unavailable in {remainingDays} days. Should you have any questions, please contact Matt
                at matt@tru-tech.org.
                <a target="_blank" href="https://v2.choice-link.net/" rel="noopener noreferrer">
                  Go to the new version
                </a>
              </div>
              <UpdateApp />
              <AnnouncementAlert />
              <ToastContainer />

              <Outlet />
            </Content>
          </Layout>
        </Layout>

        <Footer style={{ textAlign: "center", backgroundColor: "#212226", color: "white" }}>
          ©2023 Created by Tru-Tech - V: {packageInfo.version}
        </Footer>
      </Layout>
    </Fragment>
  );
};

export default MyLayout;
