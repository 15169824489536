import React, { useEffect, useContext, Fragment, useState } from "react";
import SettingsContext from "../../../../context/settings/settingsContext";
import AuthContext from "../../../../context/auth/authContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "antd";

const defaultValues = {};
const SettingsForm = () => {
  const settingsContext = useContext(SettingsContext);
  const { settings, getSettings, updateSettings } = settingsContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const navigate = useNavigate();
  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    updateSettings(data);
    navigate(-1);
  };

  const setExit = () => {
    navigate(-1);
    // clearCurrentDoor();
  };

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    settings && reset(settings);
  }, [settings]);

  return (
    <Fragment>
      <div className="container-fluid pb-4">
        <div className="row">
          <div className="col-md-12">
            <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
              Back
            </button>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <h4 className="text-center">Settings</h4>
          </div>
        </div>
        <div className="row">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className=" border settingsform  " style={{ borderRadius: "5px" }}>
              <div className="col-md-12">
                <div className="row mb-2 mt-2">
                  <Fragment>
                    <div className="col-md-5">
                      <h4 className="text-center">My Information</h4>
                      <div className="form-group">
                        <label> Name</label>
                        <input className="form-control inputbg" name="name" {...register("name", { required: true })} />
                      </div>
                      <div className="form-group">
                        <label> Email</label>
                        <input className="form-control inputbg" name="email" {...register("email", { required: true })} />
                      </div>
                      <div className="form-group">
                        <label> Street Address</label>
                        <input
                          className="form-control inputbg"
                          name="streetAddress"
                          {...register("streetAddress", { required: true })}
                        />
                      </div>
                      <div className="form-group">
                        <label> City</label>
                        <input className="form-control inputbg" name="city" {...register("city", { required: true })} />
                      </div>
                      <div className="form-group">
                        <label> State</label>
                        <input className="form-control inputbg" name="state" {...register("state", { required: true })} />
                      </div>
                      <div className="form-group">
                        <label> Zip Code</label>
                        <input className="form-control inputbg" name="zipCode" {...register("zipCode", { required: true })} />
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5">
                      <h4 className="text-center mt-4">Vendor Information</h4>
                      <div className="form-group">
                        <label>Vendor Name</label>
                        <input
                          className="form-control inputbg"
                          name="vendorName"
                          {...register("vendorName", { required: true })}
                        />
                      </div>
                      <div className="form-group">
                        <label>Vendor Email</label>
                        <input
                          className="form-control inputbg"
                          name="vendorEmail"
                          {...register("vendorEmail", { required: true })}
                        />
                      </div>
                      <div className="form-group">
                        <label>Vendor Street Address</label>
                        <input
                          className="form-control inputbg"
                          name="vendorStreetAddress"
                          {...register("vendorStreetAddress", { required: true })}
                        />
                      </div>
                      <div className="form-group">
                        <label>Vendor City</label>
                        <input
                          className="form-control inputbg"
                          name="vendorCity"
                          {...register("vendorCity", { required: true })}
                        />
                      </div>
                      <div className="form-group">
                        <label>Vendor State</label>
                        <input
                          className="form-control inputbg"
                          name="vendorState"
                          {...register("vendorState", { required: true })}
                        />
                      </div>
                      <div className="form-group">
                        <label>Vendor Zip Code</label>
                        <input
                          className="form-control inputbg"
                          name="vendorZipCode"
                          {...register("vendorZipCode", { required: true })}
                        />
                      </div>
                    </div>
                  </Fragment>
                  {user.firstChoiceCustomer && (
                    <Fragment>
                      <div className="col-md-6">
                        <div className="form-group mt-4">
                          <label>Customer ID</label>
                          <input
                            className="form-control inputbg"
                            name="strCustomerID"
                            {...register("strCustomerID", { required: true })}
                          />
                        </div>

                        {/* <div className="form-group">
                          <label>API KEY</label>
                          <input className="form-control inputbg" name="apiKey" {...register("apiKey", { required: true })} />
                        </div> */}
                      </div>
                    </Fragment>
                  )}

                  <div className="form-group mt-4">
                    <button className="btn btn-prime float-end" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default SettingsForm;
