import React, { useState } from "react";
import { Checkbox } from "antd";

const ItemCreateForm = ({ handleSubmit, handleChange, setValues, setCategory, values }) => {
  // destructure
  const { name, strProductID } = values;

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3"> </div>

          <div className="col-md-4 bidformbackground border " style={{ borderRadius: "5px" }}>
            <h4 className="text-center mt-2">Add Item</h4>
            <div className="form-group">
              <label className="mt-2">Name</label>
              <input type="text" name="name" className="form-control inputbg" value={name} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label className="mt-2">Product ID</label>
              <input
                type="text"
                name="strProductID"
                className="form-control inputbg"
                value={strProductID}
                onChange={handleChange}
              />
            </div>

            <br />
            <button className="btn btn-outline-info float-end mb-2">Save</button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ItemCreateForm;
