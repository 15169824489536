import React, { useEffect, useContext } from "react";
import Alerts from "../../layout/Alerts";
import { Spin } from "antd";

const CompanyHome = () => {
  useEffect(() => {}, []);

  return (
    <div>
      <Alerts />
      <div className="row">
        <div className="col-md-6">Company Home</div>
      </div>
    </div>
  );
};

export default CompanyHome;
