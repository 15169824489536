import React, { useEffect, useContext } from "react";
import ComponentCategoryContext from "../../../../../../context/componentCategory/componentCategoryContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Checkbox } from "antd";

const defaultValues = {};
const ComponentCategoryForm = () => {
  const navigate = useNavigate();
  const componentCategoryContext = useContext(ComponentCategoryContext);
  const { addComponentCategory, updateComponentCategory, currentComponentCategory, clearCurrentComponentCategory } =
    componentCategoryContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    currentComponentCategory && reset(currentComponentCategory);
  }, []);

  const onSubmit = (data) => {
    if (!currentComponentCategory) {
      addComponentCategory(data);
      navigate(-1);
    } else {
      updateComponentCategory(data);
      navigate(-1);
      clearCurrentComponentCategory();
    }
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentComponentCategory();
  };

  return (
    <div className="container pb-4">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center">Add Category</h4>
          {/* {currentDoor && (
            <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
              Exit
            </button>
          )} */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="simpleform " style={{ borderRadius: "5px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row mb-3">
                      <div className="col-md-9">
                        <div className="form-group">
                          <label>Name</label>
                          <input className="form-control inputbg" name="name" {...register("name", { required: true })} />
                        </div>
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="metalBuilding"
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                              <Checkbox
                                onBlur={onBlur} // notify when input is touched
                                onChange={onChange} // send value to hook form
                                checked={value}
                                inputRef={ref}
                              >
                                Metal Building
                              </Checkbox>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mt-4">
                        <div className="buttons float-end">
                          <button className="btn btn-prime" type="submit">
                            Save
                            {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ComponentCategoryForm;
