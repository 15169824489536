import React, { useEffect, useContext } from "react";
import SettingsForm from "./SettingsForm";

const ShopSettings = () => {
  useEffect(() => {}, []);

  return <SettingsForm />;
};

export default ShopSettings;
