import React, { useState, Fragment, useEffect, useContext } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Table, Modal } from "antd";

import ComponentCategoryContext from "../../../../../context/componentCategory/componentCategoryContext";
import ComponentContext from "../../../../../context/component/componentContext";
import ItemContext from "../../../../../context/item/itemContext";
import SettingsContext from "../../../../../context/settings/settingsContext";

import { DeleteOutlined } from "@ant-design/icons";

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
}

export default function Fields({ control, register, setValue, getValues, watch, errors }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "metalOrder",
  });

  const windowWidth = useWindowWidth();
  const isMobileView = windowWidth <= 1300;

  const componentContext = useContext(ComponentContext);
  const { components, getComponents } = componentContext;

  const componentCategoryContext = useContext(ComponentCategoryContext);
  const { componentCategorys, getComponentCategorys } = componentCategoryContext;

  const settingsContext = useContext(SettingsContext);
  const { settings, getSettings } = settingsContext;

  const itemContext = useContext(ItemContext);
  const { allItems, getItems, clearItems } = itemContext;

  useEffect(() => {
    getComponentCategorys();
    getSettings();
    getItems();
  }, []);

  const sortedComponentCategories =
    componentCategorys &&
    componentCategorys.sort((a, b) => {
      if (a.metalBuilding === b.metalBuilding) return 0;
      if (a.metalBuilding) return 1; // if a has metalPackage true, it should come later
      return -1; // otherwise, a should come first
    });

  const categoryOptions =
    sortedComponentCategories &&
    sortedComponentCategories.map((option) => {
      return { label: `${option.name}`, value: option };
    });

  const componentOptions =
    components &&
    components.map((option) => {
      return { label: `${option.name}`, value: option };
    });

  const roofColorOptions =
    settings &&
    settings.roofColors.map((option) => {
      return { label: `${option.color.name}`, value: option.color.code };
    });

  const sidingColorOptions =
    settings &&
    settings.sidingColors.map((option) => {
      return { label: `${option.color.name}`, value: option.color.code };
    });

  const trimColorOptions =
    settings &&
    settings.trimColors.map((option) => {
      return { label: `${option.color.name}`, value: option.color.code };
    });

  const [variations, setVariations] = useState();
  const [showFields, setShowFields] = useState({});

  const setMetalOrderChange = (e, metalOrderIndex) => {
    // console.log("e.value.metalBuilding:", e.value?.metalBuilding ?? false);
    // console.log("metalOrderIndex:", metalOrderIndex);

    const currentCategory = getValues(`metalOrder[${metalOrderIndex}].category`);
    // console.log("this is the currentCategory", currentCategory, selectedCategory?._id);
    if (currentCategory._id !== selectedCategory?._id) {
      // console.log("this is not the same category");
      Modal.warning({
        title: "Category Mismatch",
        content: "You must reselect the Category before changing the Size.",
        // Optionally, you can handle the OK action
        onOk() {
          console.log("OK clicked");
          // Additional logic when OK is clicked, if necessary
        },
      });

      return;
    }

    setShowFields((prevState) => {
      // console.log("Previous state of showFields:", prevState);
      const newState = { ...prevState, [metalOrderIndex]: e.value?.metalBuilding ?? false };
      // console.log("New state of showFields:", newState);
      return newState;
    });

    let name = { label: e.label, value: e.value._id };
    setValue(`metalOrder[${metalOrderIndex}].metalPackage.name`, name);
    const newVariations = e.value.variations.map((variation) => {
      return { label: variation.name, value: variation };
    });
    setVariations(newVariations);
    setValue(`metalOrder[${metalOrderIndex}].metalPackage._id`, e.value._id);

    let varName = { label: newVariations[0].label, value: newVariations[0].value._id };
    setValue(`metalOrder[${metalOrderIndex}].variation.name`, varName);

    setValue(`metalOrder[${metalOrderIndex}].metalPackage.items`, newVariations[0].value.items);
    setValue(`metalOrder[${metalOrderIndex}].variation._id`, newVariations[0].value._id);
  };

  const setVariationChange = (e, metalOrderIndex) => {
    const currentCategory = getValues(`metalOrder[${metalOrderIndex}].category`);
    // console.log("this is the currentCategory", currentCategory, selectedCategory?._id);
    if (currentCategory._id !== selectedCategory?._id) {
      // console.log("this is not the same category");
      Modal.warning({
        title: "Category Mismatch",
        content: "You must reselect the Category before changing the Variation.",
        // Optionally, you can handle the OK action
        onOk() {
          console.log("OK clicked");
          // Additional logic when OK is clicked, if necessary
        },
      });

      return;
    }
    let name = { label: e.label, value: e.value._id };
    setValue(`metalOrder[${metalOrderIndex}].variation.name`, name);

    setValue(`metalOrder[${metalOrderIndex}].metalPackage.items`, e.value.items);
    setValue(`metalOrder[${metalOrderIndex}].variation._id`, e.value._id);
  };

  const setSerialNumber = (serialNumber, metalOrderIndex) => {
    setValue(`metalOrder[${metalOrderIndex}].serialNumber`, serialNumber);
  };

  const setZone = (category, index) => {
    setSelectedCategory(category.value);
    // Set the shedCategory field in the form
    // setValue(`metalOrder[${index}].componentCategory`, category.value._id);
    getComponents(category.value._id);
    setValue(`metalOrder[${index}].metalPackage.items`, []);
    setValue(`metalOrder[${index}].metalPackage.name`, null);

    setValue(`metalOrder[${index}].metalPackage._id`, null);
    setZoneSelected(false);
  };

  const setRoofColorChange = (e, metalOrderIndex) => {
    setValue(`metalOrder[${metalOrderIndex}].roofColor`, e);
  };
  const setSidingColorChange = (e, metalOrderIndex) => {
    setValue(`metalOrder[${metalOrderIndex}].sidingColor`, e);
  };
  const setTrimColorChange = (e, metalOrderIndex) => {
    setValue(`metalOrder[${metalOrderIndex}].trimColor`, e);
    // console.log("This is e", e);
  };

  const [zoneSelected, setZoneSelected] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState();

  // metalOrder.value && setValue(`metalOrder[${metalOrderIndex}].value.label`, metalOrder.value.title);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const newSelectedOptions = fields.map((field) => {
      const initialShedCategoryId = field.shedCategory;
      return categoryOptions?.find((option) => option.value._id === initialShedCategoryId);
    });

    // Check if newSelectedOptions is different from the current selectedOptions
    if (JSON.stringify(newSelectedOptions) !== JSON.stringify(selectedOptions)) {
      setSelectedOptions(newSelectedOptions);
    }
  }, [fields, categoryOptions, selectedOptions]);

  const handleDeleteLine = (e, fieldId) => {
    e.preventDefault();
    const index = fields.findIndex((field) => field.id === fieldId);
    remove(index);
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text, record, index) => {
        return (
          <Controller
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={categoryOptions}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                value={(field.value && { label: field.value.name, value: field.value._id }) || null}
                onChange={(e) => {
                  field.onChange(e.value);
                  setZone(e, index);
                }}
              />
            )}
            name={`metalOrder[${index}].category`}
            control={control}
          />
        );
      },
    },

    {
      title: "Size",
      dataIndex: "metalPackage",
      key: "metalPackage",
      render: (text, record, index) => (
        <Controller
          render={({ field }) => (
            <ReactSelect
              {...field}
              isDisabled={!componentOptions}
              options={componentOptions}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              onChange={(e) => setMetalOrderChange(e, index)}
            />
          )}
          name={`metalOrder[${index}].metalPackage.name`}
          control={control}
        />
      ),
    },
    {
      title: "Variation",
      dataIndex: "variation",
      key: "variation",
      render: (text, record, index) => (
        <Controller
          render={({ field }) => (
            <ReactSelect
              {...field}
              isDisabled={!variations}
              options={variations}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              onChange={(e) => setVariationChange(e, index)}
            />
          )}
          name={`metalOrder[${index}].variation.name`}
          control={control}
        />
      ),
    },
    {
      title: "Serial Number",
      dataIndex: "metalPackage",
      key: "metalPackage",
      render: (text, record, index) => (
        <Controller
          render={({ value, field, onChange, name, ...restProps }) => (
            <input
              className="form-control inputbg"
              name={name}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              onChange={(e) => {
                const serialNum = e.target.value;
                setSerialNumber(serialNum);
              }}
              required
              {...restProps}
              {...field}
            />
          )}
          control={control}
          name={`metalOrder[${index}].serialNumber`}
        />
      ),
    },
    {
      title: "Roof Color",
      dataIndex: "roofColor",
      key: "roofColor",
      render: (text, record, index) => (
        <Controller
          render={({ field }) => (
            <ReactSelect
              {...field}
              options={roofColorOptions}
              isClearable={true}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              onChange={(e) => setRoofColorChange(e, index)}
              required
            />
          )}
          name={`metalOrder[${index}].roofColor`}
          control={control}
          rules={{ required: true }}
        />
      ),
    },
    {
      title: "Siding Color",
      dataIndex: "sidingColor",
      key: "sidingColor",

      render: (text, record, index) => {
        // console.log("text:", text);
        // console.log("showFields[index]:", showFields[index]);
        return showFields[index] === true || (text && Object.keys(text).length) ? (
          <Controller
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={sidingColorOptions}
                isClearable={true}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                onChange={(e) => setSidingColorChange(e, index)}
                required
              />
            )}
            name={`metalOrder[${index}].sidingColor`}
            control={control}
            rules={{ required: true }}
          />
        ) : null;
      },
    },
    {
      title: "Trim Color",
      dataIndex: "trimColor",
      key: "trimColor",
      render: (text, record, index) =>
        showFields[index] === true || (text && Object.keys(text).length) ? (
          <Controller
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={trimColorOptions}
                isClearable={true}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                onChange={(e) => setTrimColorChange(e, index)}
                required
              />
            )}
            name={`metalOrder[${index}].trimColor`}
            control={control}
            rules={{ required: true }}
          />
        ) : null,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      key: "action",
      render: (_, record, index) => (
        // <button className="btn btn-danger float-end" onClick={(e) => handleDeleteLine(e, index)}>
        //   Delete
        // </button>
        <DeleteOutlined className="text-danger" style={{ fontSize: "20px" }} onClick={(e) => handleDeleteLine(e, index)} />
      ),
    },
  ];

  const mobileColumns = [
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (text, record, index) => (
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="col-md-6">
                <strong>Category:</strong>
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      options={categoryOptions}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      value={(field.value && { label: field.value.name, value: field.value._id }) || null}
                      onChange={(e) => {
                        field.onChange(e.value);
                        setZone(e, index);
                      }}
                    />
                  )}
                  name={`metalOrder[${index}].category`}
                  control={control}
                />
              </div>

              <div className="col-md-5">
                <strong>Size:</strong>
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      isDisabled={!componentOptions}
                      options={componentOptions}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      onChange={(e) => setMetalOrderChange(e, index)}
                    />
                  )}
                  name={`metalOrder[${index}].metalPackage.name`}
                  control={control}
                />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1em" }}>
              <div className="col-md-5">
                <strong>Variation:</strong>
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      isDisabled={!variations}
                      options={variations}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      onChange={(e) => setVariationChange(e, index)}
                    />
                  )}
                  name={`metalOrder[${index}].variation.name`}
                  control={control}
                />
              </div>

              <div className="col-md-6">
                <strong>Serial Number:</strong>
                <Controller
                  render={({ value, field, onChange, name, ...restProps }) => (
                    <input
                      className="form-control inputbg"
                      name={name}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      onChange={(e) => {
                        const serialNum = e.target.value;
                        setSerialNumber(serialNum);
                      }}
                      required
                      {...restProps}
                      {...field}
                    />
                  )}
                  control={control}
                  name={`metalOrder[${index}].serialNumber`}
                />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1em" }}>
              <div className="col-md-3">
                <strong>Roof Color:</strong>
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      options={roofColorOptions}
                      isClearable={true}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      onChange={(e) => setRoofColorChange(e, index)}
                      required
                    />
                  )}
                  name={`metalOrder[${index}].roofColor`}
                  control={control}
                  rules={{ required: true }}
                />
              </div>

              <div className="col-md-3">
                <strong>Siding Color:</strong>
                {showFields[index] === true || (text && Object.keys(text).length) ? (
                  <Controller
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        options={sidingColorOptions}
                        isClearable={true}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        onChange={(e) => setSidingColorChange(e, index)}
                        required
                      />
                    )}
                    name={`metalOrder[${index}].sidingColor`}
                    control={control}
                    rules={{ required: true }}
                  />
                ) : (
                  <div className="col-md-3"></div>
                )}
              </div>

              <div className="col-md-3">
                <strong>Trim Color:</strong>
                {showFields[index] === true || (text && Object.keys(text).length) ? (
                  <Controller
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        options={trimColorOptions}
                        isClearable={true}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        onChange={(e) => setTrimColorChange(e, index)}
                        required
                      />
                    )}
                    name={`metalOrder[${index}].trimColor`}
                    control={control}
                    rules={{ required: true }}
                  />
                ) : (
                  <div className="col-md-3"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record, index) => (
        <DeleteOutlined className="text-danger" style={{ fontSize: "20px" }} onClick={(e) => handleDeleteLine(e, index)} />
      ),
    },
  ];

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const dataSource = fields.map((item, index) => ({ key: index, ...item }));

  return (
    <div className="container mt-4 ">
      <Table
        dataSource={dataSource}
        loading={categoryOptions === null}
        columns={isMobileView ? mobileColumns : columns}
        pagination={false}
        expandable={{
          expandedRowRender: (record, index) => (
            <Fragment>
              <NestedItems control={control} name={`metalOrder[${index}].metalPackage.items`} allItems={allItems} />
              <NestedCustomItems control={control} name={`metalOrder[${index}].metalPackage.customItems`} />
            </Fragment>
          ),
          expandedRowKeys,
          onExpand: (expanded, record) => {
            if (expanded) {
              setExpandedRowKeys((prev) => [...prev, record.key]);
            } else {
              setExpandedRowKeys((prev) => prev.filter((key) => key !== record.key));
            }
          },
          defaultExpandAllRows: true,
        }}
      />

      <button
        className="btn btn-second mb-2 mt-2 float-end"
        onClick={(e) => {
          e.preventDefault();
          append({ metalPackage: { items: [] } });
        }}
      >
        Add Order
      </button>
    </div>
  );
}

function NestedItems({ control, name, allItems }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const itemOptions = allItems && allItems.map((item) => ({ label: item.name, value: item }));
  const nestedColumns = [
    {
      title: "Item Name",
      dataIndex: "item",
      key: "name",
      render: (text, record, index) => {
        return (
          <>
            <Controller
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  options={itemOptions}
                  value={(field.value && { label: field.value.name, value: field.value }) || null}
                  // isClearable={true}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                />
              )}
              name={`${name}[${index}].item`}
              control={control}
            />
          </>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record, index) => (
        <Controller
          control={control}
          name={`${name}[${index}].quantity`}
          render={({ field }) => <input {...field} className="form-control custom-input readonlyinput" />}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      key: "action",
      render: (_, record, index) => (
        // <button className="btn btn-danger float-end" onClick={() => remove(index)}>
        //   Delete Item
        // </button>
        <DeleteOutlined className="text-danger" style={{ fontSize: "15px" }} onClick={() => remove(index)} />
      ),
    },
  ];
  return (
    <div>
      <Table dataSource={fields} columns={nestedColumns} rowKey="id" pagination={false} />
      <button
        className="btn btn-second mt-2 float-start"
        onClick={(e) => {
          e.preventDefault();
          append({ item: { name: "" }, quantity: 0 });
        }}
      >
        Add Item
      </button>
    </div>
  );
}

function NestedCustomItems({ control, name }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const nestedColumns = [
    {
      title: "Item Name",
      dataIndex: "item",
      key: "name",
      render: (text, record, index) => {
        return (
          <>
            <Controller
              control={control}
              name={`${name}[${index}].item.name`}
              render={({ field }) => {
                return <input {...field} className="form-control custom-input readonlyinput" />;
              }}
            />
          </>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record, index) => (
        <Controller
          control={control}
          name={`${name}[${index}].quantity`}
          render={({ field }) => <input {...field} className="form-control custom-input readonlyinput" />}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      key: "action",
      render: (_, record, index) => (
        <DeleteOutlined className="text-danger" style={{ fontSize: "15px" }} onClick={() => remove(index)} />
      ),
    },
  ];

  return (
    <div>
      {fields && fields.length > 0 && <Table dataSource={fields} columns={nestedColumns} rowKey="id" pagination={false} />}

      <button
        className="btn btn-second mt-2 float-end"
        onClick={(e) => {
          e.preventDefault();
          append({ item: { name: "" }, quantity: 0 });
        }}
      >
        Add Custom Item
      </button>
    </div>
  );
}
