import React, { useEffect, useContext } from "react";
import ComponentContext from "../../../../../../context/component/componentContext";
import ComponentCategoryContext from "../../../../../../context/componentCategory/componentCategoryContext";
import ItemContext from "../../../../../../context/item/itemContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ItemArray from "./ComponentFormItemArr";
import VariationArray from "./ComponentFormVariationArr";
import NumberFormat from "react-number-format";
import { Checkbox } from "antd";

const defaultValues = {
  name: "",
  category: null,
  items: [
    {
      item: {},
    },
  ],
};
const ShopComponentForm = () => {
  const navigate = useNavigate();
  const componentContext = useContext(ComponentContext);
  const { addComponent, currentComponent, clearCurrentComponent, updateComponent } = componentContext;

  const componentCategoryContext = useContext(ComponentCategoryContext);
  const { currentComponentCategory } = componentCategoryContext;

  const itemContext = useContext(ItemContext);
  const { getItems, allItems } = itemContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    getItems();
    currentComponent && reset(currentComponent);
    currentComponentCategory && setValue("category", currentComponentCategory._id);
    return () => {};
  }, []);

  const onSubmit = (data) => {
    console.log("this is the data", data);
    if (!currentComponent) {
      addComponent(data);
      navigate(-1);
    } else {
      updateComponent(data);
      navigate(-1);
      clearCurrentComponent();
    }
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentComponent();
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center">{currentComponent ? "Update Component" : "Add Component"}</h4>

          <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
            Back
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <form className=" orderform  " onSubmit={handleSubmit(onSubmit)}>
            <div className=" border p-4  " style={{ borderRadius: "5px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3 mt-4">
                        <div className="form-group"></div>
                      </div>

                      <div className="col-md-8"></div>

                      <div className="col-md-1">
                        <div className="buttons float-end  mr-3 mt-3">
                          <button className="btn btn-outline-primary " type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Name</label>
                          <input className="form-control inputbg" name="name" {...register("name", { required: true })} />
                        </div>
                      </div>
                      <div className="form-group">
                        <Controller
                          control={control}
                          name="metalBuilding"
                          render={({ field: { onChange, onBlur, value, name, ref } }) => (
                            <Checkbox
                              onBlur={onBlur} // notify when input is touched
                              onChange={onChange} // send value to hook form
                              checked={value}
                              inputRef={ref}
                            >
                              Metal Building
                            </Checkbox>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <VariationArray
                {...{
                  control,
                  watch,
                  register,
                  defaultValues,
                  getValues,
                  setValue,
                  errors,
                  getValues,
                  allItems,
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopComponentForm;
