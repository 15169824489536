import React, { Fragment, useEffect, useState, useContext } from "react";
import ComponentContext from "../../../../../../context/component/componentContext";
import ComponentCategoryContext from "../../../../../../context/componentCategory/componentCategoryContext";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";

import "antd/dist/antd.css";
import { Table } from "antd";

const ShopAllComponents = () => {
  const navigate = useNavigate();
  const componentContext = useContext(ComponentContext);
  const {
    getComponents,
    components,
    filteredComponents,
    loadingComponent,
    setCurrentComponent,
    clearCurrentComponent,
    updateAllComponents,
    deleteComponent,
  } = componentContext;

  const componentCategoryContext = useContext(ComponentCategoryContext);
  const { currentComponentCategory } = componentCategoryContext;

  useEffect(() => {
    clearCurrentComponent();
  }, []);

  useEffect(() => {
    getComponents(currentComponentCategory._id);
  }, []);

  useEffect(() => {}, [components]);

  const [newFile, setNewFile] = useState("");
  let itemsForExport = [];

  components &&
    components.map((component) => {
      component.variations &&
        component.variations.map((variation, variationIndex) => {
          let componentItem = {};
          componentItem.id = component._id;
          componentItem.category = component.category;
          componentItem.Name = component.name;
          componentItem.MetalBuilding = component.metalBuilding;
          componentItem.Variation_Name = variation.name;

          variation.items &&
            variation.items.map((item, itemIndex) => {
              componentItem[`ID_${itemIndex + 1}`] = item.item._id;
              componentItem[`Name_${itemIndex + 1}`] = item.item.name;
              componentItem[`Count_${itemIndex + 1}`] = item.quantity;
            });

          itemsForExport.push(componentItem);
        });
    });

  const exportFile = () => {
    const ws = utils.json_to_sheet(itemsForExport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, `${currentComponentCategory.name}.xlsx`);
  };
  const importFile = () => {
    updateAllComponents(newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      // console.log(JSON.stringify(json, null, 2));
      // let jsonOrders = JSON.stringify(json);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const handleDeleteComponent = (component) => {
    deleteComponent(component._id);
  };

  const columns = [
    {
      title: "Name",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },

    {
      title: "Edit",
      width: "10%",
      render: (text, record) => (
        <span>
          {components && (
            <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewComponent(record)}>
              View
            </button>
          )}
          <button className="btn btn-xs float-end btn-outline-danger" type="button" onClick={() => handleDeleteComponent(record)}>
            Delete
          </button>
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewComponent = (component) => {
    setCurrentComponent(component);
    navigate("/shop/metalpackage/form");
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleAddComponent = () => {
    navigate("/shop/metalpackage/form");
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-2">
          <button className="mb-2 btn btn-prime float-start" onClick={() => handleAddComponent()}>
            Add Component
          </button>
        </div>
        <div className="col-8">
          {components && (
            <button className="mb-2 me-2 btn btn-second  " onClick={() => exportFile()}>
              Export Excel
            </button>
          )}
        </div>
        <div className="col-2">
          <button className="mb-2 btn btn-danger float-end" onClick={() => handleBack()}>
            Back
          </button>
        </div>
      </div>
      <Table
        rowClassName={() => "hover-row"}
        loading={!loadingComponent && components ? false : true}
        pagination={{ pageSize: 60 }}
        columns={columns}
        dataSource={filteredComponents && filteredComponents !== null ? filteredComponents : components && components}
        // dataSource={testModels}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
      <div className="row">
        <div className="col-md-4">
          <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
        </div>
        <div className="col-md-4">
          <button className="mb-2 me-2 btn btn-outline-info" onClick={() => importFile()}>
            Upload Updated BOM
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default ShopAllComponents;
