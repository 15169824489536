import React, { Fragment, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Menu, Layout } from "antd";
import { HomeOutlined, MenuOutlined } from "@ant-design/icons";

import AuthContext from "../../context/auth/authContext";

const { Header } = Layout;
const { SubMenu } = Menu;

const Navbar = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState();
  const [counter, setCounter] = useState(0);

  const authContext = useContext(AuthContext);
  const { logout, user, setIsAuthenticated, isAuthenticated } = authContext;

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const decodedJwt = localStorage.token && parseJwt(localStorage.token);

  const onLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    if (decodedJwt && decodedJwt.exp * 1000 < Date.now()) {
      setIsAuthenticated(false);
    }
  }, []);

  const handleMenuChange = (e) => {
    setCurrent(e.key);
  };

  const authLinks = (
    <Header theme="light" className="myheader ">
      <div className="myheaderitem">
        <>CHOICE-LINK </>
      </div>

      <div className="myheaderitemtwo">
        <div className="myheaderitemtwoItems">{user && user.name}</div>
      </div>

      {user && (user.role === "Office User" || user.role === "Office Admin") && (
        <Menu
          className="myheaderitem"
          // theme="light"
          onClick={handleMenuChange}
          selectedKeys={[current]}
          mode="horizontal"
          // style={{ width: "100px" }}
        >
          <Menu.Item key="home" icon={<HomeOutlined />}>
            <Link to="/company/home"></Link>
          </Menu.Item>
          <SubMenu key="sub22" icon={<MenuOutlined />}>
            <Menu.Item key="settings">
              <Link to="/company/settings">Settings</Link>
            </Menu.Item>
            <Menu.Item key="logout">
              <a onClick={onLogout} href="#!">
                <span className="hide-sm">Logout</span>
              </a>
            </Menu.Item>
          </SubMenu>
          {/* <Menu.Item key='admin' icon={<SettingOutlined />}>
          <Link to='/admindashboard'>Admin</Link>
        </Menu.Item> */}
        </Menu>
      )}

      {user && (user.role === "Shop User" || user.role === "Shop Admin") && (
        <Menu
          className="myheaderitem"
          // theme="light"
          onClick={handleMenuChange}
          selectedKeys={[current]}
          mode="horizontal"
          // style={{ width: "100px" }}
        >
          <Menu.Item key="home" icon={<HomeOutlined />}>
            <Link to="/shop/home"></Link>
          </Menu.Item>
          <SubMenu key="sub22" icon={<MenuOutlined />}>
            <Menu.Item key="settings">
              <Link to="/shop/settings">Settings</Link>
            </Menu.Item>

            {user && user.name === "Matt Schmidt" && (
              <Fragment>
                <Menu.Item key="admin">
                  <Link to="/admin">Admin</Link>
                </Menu.Item>
                {/* <Menu.Item key="logs">
                  <Link to="/shop/logs">Logs</Link>
                </Menu.Item> */}
              </Fragment>
            )}
            <Menu.Item key="logout">
              <a onClick={onLogout} href="#!">
                <span className="hide-sm">Logout</span>
              </a>
            </Menu.Item>
          </SubMenu>
          {/* <Menu.Item key='admin' icon={<SettingOutlined />}>
          <Link to='/admindashboard'>Admin</Link>
        </Menu.Item> */}
        </Menu>
      )}
    </Header>
  );

  const guestLinks = (
    <Header theme="light" className="myheader">
      <Menu onClick={handleMenuChange} mode="horizontal" theme="light">
        <Menu.Item key="login">
          <Link to="/login">Login</Link>
        </Menu.Item>
        {/* <Menu.Item key='register'>
            <Link to='/register'>Login</Link>
          </Menu.Item> */}
      </Menu>
    </Header>
  );
  // console.log("This is isAuthenticated", isAuthenticated);

  return <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>;
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

Navbar.defaultProps = {
  title: "CHOICE-LINK",
};

export default Navbar;
