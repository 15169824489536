import React, { Fragment, useEffect, useContext } from "react";
import RoofColorColumns from "./RoofColorColumns";

const AllRoofColors = ({ roofColors, setSelectedRoofColor, selectedRoofColor, settings, updateSettings }) => {
  const theTitle = "Roof Colors";

  return (
    <RoofColorColumns
      roofColors={roofColors}
      setSelectedRoofColor={setSelectedRoofColor}
      selectedRoofColor={selectedRoofColor}
      theTitle={theTitle}
      settings={settings}
      updateSettings={updateSettings}
    />
  );
};

export default AllRoofColors;
