import React, { useEffect, useContext } from "react";
import ItemCategoryContext from "../../../../../../context/itemCategory/itemCategoryContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

const defaultValues = {};
const ItemCategoryForm = () => {
  const navigate = useNavigate();
  const itemCategoryContext = useContext(ItemCategoryContext);
  const { addItemCategory, updateItemCategory, currentItemCategory, clearCurrentItemCategory } = itemCategoryContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    currentItemCategory && reset(currentItemCategory);
  }, []);

  const onSubmit = (data) => {
    if (!currentItemCategory) {
      addItemCategory(data);
      navigate(-1);
    } else {
      updateItemCategory(data);
      navigate(-1);
      clearCurrentItemCategory();
    }
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentItemCategory();
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center">Add Item Category</h4>
          {/* {currentDoor && (
            <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
              Exit
            </button>
          )} */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="simpleform  " style={{ borderRadius: "5px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row mb-3">
                      <div className="col-md-9">
                        <div className="form-group">
                          <label>Name</label>
                          <input className="form-control inputbg" name="name" {...register("name", { required: true })} />
                        </div>
                      </div>
                      <div className="col-md-3 mt-4">
                        <div className="buttons float-end ">
                          <button className="btn btn-prime " type="submit">
                            Save
                            {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ItemCategoryForm;
