import React, { useState, Fragment, useEffect, useContext } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Table } from "antd";

import ItemContext from "../../../../../../context/item/itemContext";

export default function Fields({ control, register, setValue, getValues, watch, errors }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "variations",
  });

  const itemContext = useContext(ItemContext);
  const { allItems, getItems, clearItems } = itemContext;

  useEffect(() => {
    getItems();
  }, []);

  const itemOptions =
    allItems &&
    allItems.map((item) => {
      return { value: item._id, label: item.name };
    });

  const handleDeleteLine = (e, fieldId) => {
    e.preventDefault();
    const index = fields.findIndex((field) => field.id === fieldId);
    remove(index);
  };

  const columns = [
    {
      title: "Variation Name",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <Controller
          render={({ field }) => <input {...field} className="form-control inputbg" />}
          name={`variations[${index}].name`}
          control={control}
        />
      ),
    },

    {
      title: "Action",
      dataIndex: "action",

      key: "action",
      render: (_, record, index) => (
        <button className="btn btn-danger float-end" onClick={(e) => handleDeleteLine(e, index)}>
          Delete Variation
        </button>
      ),
    },
  ];

  return (
    <div className="container mt-4 ">
      <Table
        dataSource={fields}
        // loading={categoryOptions === null}
        columns={columns}
        pagination={false}
        expandable={{
          expandedRowRender: (record, index) => (
            <NestedItems control={control} name={`variations[${index}].items`} allItems={allItems} />
          ),
          rowExpandable: (record) => true,
        }}
      />

      <button
        className="btn btn-second mb-2 mt-2 float-end"
        onClick={(e) => {
          e.preventDefault();
          append({ metalPackage: { items: [] } });
        }}
      >
        Add Variation
      </button>
    </div>
  );
}

function NestedItems({ control, name, allItems }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const itemOptions = allItems && allItems.map((item) => ({ label: item.name, value: item }));

  const nestedColumns = [
    {
      title: "Item Name",
      dataIndex: "item",
      key: "name",
      render: (text, record, index) => {
        return (
          <>
            <Controller
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  options={itemOptions}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  value={(field.value && { label: field.value.name, value: field.value._id }) || null}
                  onChange={(option) => field.onChange({ name: option.label, _id: option.value })}
                />
              )}
              name={`${name}[${index}].item`}
              control={control}
            />
          </>
        );
      },
    },

    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record, index) => (
        <Controller
          control={control}
          name={`${name}[${index}].quantity`}
          render={({ field }) => <input {...field} className="form-control custom-input readonlyinput" />}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record, index) => (
        <button className="btn btn-danger float-end" onClick={() => remove(index)}>
          Delete Item
        </button>
      ),
    },
  ];

  return (
    <div>
      <Table dataSource={fields} columns={nestedColumns} rowKey="id" pagination={false} />
      <button
        className="btn btn-second mt-2 float-end"
        onClick={(e) => {
          e.preventDefault();
          append({ item: { name: "" }, quantity: 0 });
        }}
      >
        Add Item
      </button>
    </div>
  );
}
