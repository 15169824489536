import React, { Fragment, useEffect, useContext } from "react";
import UserColumns from "./UserColumns";
import AuthContext from "../../../../context/auth/authContext";

const AllUsers = () => {
  const authContext = useContext(AuthContext);
  const { getUsers, users } = authContext;

  useEffect(() => {
    getUsers();
  }, []);

  const theTitle = "Users";

  return <UserColumns users={users} theTitle={theTitle} />;
};

export default AllUsers;
