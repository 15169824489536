import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ItemCategoryContext from "../../../../../../context/itemCategory/itemCategoryContext";
import ItemContext from "../../../../../../context/item/itemContext";
import { read, utils, writeFileXLSX } from "xlsx";

import "antd/dist/antd.css";
import { Table } from "antd";

const AllItemCategories = () => {
  const navigate = useNavigate();
  const itemCategoryContext = useContext(ItemCategoryContext);
  const { getItemCategorys, itemCategorys, setCurrentItemCategory } = itemCategoryContext;

  const itemContext = useContext(ItemContext);
  const { allItems, getItems, clearItems, updateShopItems } = itemContext;

  useEffect(() => {
    getItemCategorys();
    getItems();
    // clearItems when unmounting
    return () => {
      clearItems();
    };
  }, []);

  useEffect(() => {}, [itemCategorys]);

  const columns = [
    {
      title: "Name",

      render: (record) => <Fragment>{record.name}</Fragment>,
    },

    // {
    //   title: "Edit",
    //   width: "10%",
    //   render: (text, record) => (
    //     <span>
    //       <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewItemCategory(record)}>
    //         View
    //       </button>
    //       {/* <button className="btn btn-xs btn-outline-danger ms-2" type="button" onClick={() => handleEditItemCategory(record)}>
    //         Edit
    //       </button> */}
    //     </span>
    //   ),
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddItemCategory = () => {
    navigate("/shop/items/category/form");
  };

  const handleViewItemCategory = (itemCategory) => {
    clearItems();
    setCurrentItemCategory(itemCategory);
    navigate("/shop/items/all");
  };

  const handleRowClick = (record, rowIndex) => {
    // console.log(`Clicked row with index: ${rowIndex} and record:`, record);
    clearItems();
    setCurrentItemCategory(record);
    navigate("/shop/items/all");
  };

  const handleEditItemCategory = (itemCategory) => {
    setCurrentItemCategory(itemCategory);
    navigate("/shop/itemcategory/edit");
  };

  const handleBack = () => {
    navigate(-1);
  };

  let itemsForExportByCategory = {};

  allItems &&
    allItems.forEach((item) => {
      // Group items by category
      if (!itemsForExportByCategory[item.category.name]) {
        itemsForExportByCategory[item.category.name] = [];
      }
      let newItem = {};
      newItem.Id = item._id;
      newItem.Category = item.category._id;
      newItem.Name = item.name;
      newItem.ProductID = item.strProductID;
      itemsForExportByCategory[item.category.name].push(newItem);
    });

  const exportFile = () => {
    const wb = utils.book_new();
    // Create a new worksheet for each category
    for (let category in itemsForExportByCategory) {
      const ws = utils.json_to_sheet(itemsForExportByCategory[category]);
      const sanitizedCategory = sanitizeSheetName(category);
      utils.book_append_sheet(wb, ws, sanitizedCategory);
    }
    writeFileXLSX(wb, `Items.xlsx`);
  };

  function sanitizeSheetName(name) {
    // Replace invalid characters with underscore
    return name.replace(/[:\\/?*[\]]/g, "_");
  }

  const [newFile, setNewFile] = useState("");

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      // Initialize an empty array to hold all items
      let allItems = [];
      // Iterate over all sheets in the workbook
      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        // Append items from this sheet to the allItems array
        allItems = [...allItems, ...json];
      });
      setNewFile(allItems);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };
  const importFile = () => {
    updateShopItems(newFile);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button className="mb-2 btn btn-prime" onClick={() => handleAddItemCategory()}>
                  Add Item Category
                </button>
                {allItems && allItems.length > 0 && (
                  <button className="mb-2 ms-4 btn btn-primary" onClick={() => exportFile()}>
                    Export Excel
                  </button>
                )}

                <button className="mb-2 btn btn-outline-danger float-end" onClick={() => handleBack()}>
                  Back
                </button>
              </div>
            </div>

            <Table
              rowClassName={() => "hover-row"}
              // loading={!loadingProduct && productResults ? false : true}
              pagination={false}
              columns={columns}
              // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
              dataSource={itemCategorys}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => handleRowClick(record, rowIndex), // click row
                };
              }}
            />
            <div className="row mt-4">
              <div className="col-md-3">
                <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
              </div>

              <div className="col-md-2">
                <button className="mb-2 me-2 btn btn-primary" onClick={() => importFile()}>
                  Import Excel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllItemCategories;
