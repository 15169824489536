import React, { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Modal } from "antd";
import AddSidingColor from "./AddSidingColor";

const SidingColorColumns = ({ sidingColors, setSelectedSidingColor, selectedSidingColor, settings, updateSettings }) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRemove = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Siding Color?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        // deleteRoofColor(id);
        // getMaterialOrders();
        let updatedSidingColors = [...sidingColors];
        if (id) {
          // The color's _id to be deleted
          //  const colorIdToDelete = selectedSidingColor._id;
          // Filter out the color to delete
          updatedSidingColors = updatedSidingColors.filter((color) => color._id !== id);
        }
        // Update the settings with the new roofColors array
        let updatedSettings = { ...settings, sidingColors: updatedSidingColors };
        updateSettings(updatedSettings);
      },
    });
  };

  const handleEdit = (color) => {
    setSelectedSidingColor(color);
    showModal();
  };

  const columns = [
    {
      title: "Color",
      render: (text, record) => {
        // do something like adding commas to the value or prefix
        return <span>{record.color?.name}</span>;
      },
    },
    {
      title: "Code",
      render: (text, record) => {
        // do something like adding commas to the value or prefix
        return <span>{record.color?.code}</span>;
      },
    },
    {
      title: "Actions",
      fixed: "right",
      width: "20%",
      render: (text, record) => (
        <Fragment>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleEdit(record)}>
            View
          </button>
          {/* <button className="btn btn-xs btn-outline-danger" type="button" onClick={() => handleRemove(record._id)}>
            Delete
          </button> */}
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <Modal
        // title="Add User"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null} // Remove the default footer buttons
        bodyStyle={{
          maxHeight: "50vh", // Adjust the height based on your needs
          overflowY: "auto",
        }}
      >
        <AddSidingColor selectedSidingColor={selectedSidingColor} setSelectedSidingColor={setSelectedSidingColor} />
      </Modal>
      <Table
        pagination={{ pageSize: 200 }}
        loading={sidingColors ? false : true}
        columns={columns}
        dataSource={sidingColors && sidingColors}
        rowKey="_id"
        bordered={true}
        onChange={onChange}
        scroll={{ y: "45vh" }}
      />
    </Fragment>
  );
};

export default SidingColorColumns;
