import React, { useEffect, useContext, Fragment, useState } from "react";
import SettingsContext from "../../../../../context/settings/settingsContext";
import { Modal } from "antd";
import AllRoofColors from "./roofColors/AllRoofColors";
import AddRoofColor from "./roofColors/AddRoofColor";
import AllSidingColors from "./sidingColors/AllSidingColors";
import AddSidingColor from "./sidingColors/AddSidingColor";
import AllTrimColors from "./trimColors/AllTrimColors";
import AddTrimColor from "./trimColors/AddTrimColor";

const Colors = () => {
  const settingsContext = useContext(SettingsContext);
  const { settings, getSettings, updateSettings } = settingsContext;

  const [isRoofModalVisible, setIsRoofModalVisible] = useState(false);
  const [isSidingModalVisible, setIsSidingModalVisible] = useState(false);
  const [isTrimModalVisible, setIsTrimModalVisible] = useState(false);

  const [selectedRoofColor, setSelectedRoofColor] = useState();
  const [selectedSidingColor, setSelectedSidingColor] = useState();
  const [selectedTrimColor, setSelectedTrimColor] = useState();

  // roof modal
  const showRoofModal = () => {
    setIsRoofModalVisible(true);
  };
  const handleRoofOk = () => {
    setIsRoofModalVisible(false);
  };

  const handleRoofCancel = () => {
    setIsRoofModalVisible(false);
  };

  // siding modal
  const showSidingModal = () => {
    setIsSidingModalVisible(true);
  };
  const handleSidingOk = () => {
    setIsSidingModalVisible(false);
  };

  const handleSidingCancel = () => {
    setIsSidingModalVisible(false);
  };

  // trim modal

  const showTrimModal = () => {
    setIsTrimModalVisible(true);
  };
  const handleTrimOk = () => {
    setIsTrimModalVisible(false);
  };

  const handleTrimCancel = () => {
    setIsTrimModalVisible(false);
  };

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line
  }, []);

  const handleAddRoofColor = () => {
    showRoofModal();
  };

  const handleAddSidingColor = () => {
    showSidingModal();
  };

  const handleAddTrimColor = () => {
    showTrimModal();
  };

  return (
    <Fragment>
      <div className="container">
        <div className="settingsForm">
          <Fragment>
            <Modal
              // title="Add User"
              visible={isRoofModalVisible}
              onOk={handleRoofOk}
              onCancel={handleRoofCancel}
              footer={null} // Remove the default footer buttons
              bodyStyle={{
                maxHeight: "50vh", // Adjust the height based on your needs
                overflowY: "auto",
              }}
            >
              <AddRoofColor selectedRoofColor={selectedRoofColor} setSelectedRoofColor={setSelectedRoofColor} />
            </Modal>

            <Modal
              // title="Add User"
              visible={isSidingModalVisible}
              onOk={handleSidingOk}
              onCancel={handleSidingCancel}
              footer={null} // Remove the default footer buttons
              bodyStyle={{
                maxHeight: "50vh", // Adjust the height based on your needs
                overflowY: "auto",
              }}
            >
              <AddSidingColor selectedSidingColor={selectedSidingColor} setSelectedSidingColor={setSelectedSidingColor} />
            </Modal>

            <Modal
              // title="Add User"
              visible={isTrimModalVisible}
              onOk={handleTrimOk}
              onCancel={handleTrimCancel}
              footer={null} // Remove the default footer buttons
              bodyStyle={{
                maxHeight: "50vh", // Adjust the height based on your needs
                overflowY: "auto",
              }}
            >
              <AddTrimColor selectedTrimColor={selectedTrimColor} setSelectedTrimColor={setSelectedTrimColor} />
            </Modal>

            {/* <AllUsers />
            {user && user.superAdmin ? (
              <button className="btn btn-primary" onClick={handleAddUser}>
                Add User
              </button>
            ) : null} */}

            <div className="row mt-4">
              <div className="col-md-4">
                <AllRoofColors
                  setSelectedRoofColor={setSelectedRoofColor}
                  selectedRoofColor={selectedRoofColor}
                  roofColors={settings?.roofColors}
                  settings={settings}
                  updateSettings={updateSettings}
                />
                <button className="btn btn-prime" onClick={handleAddRoofColor}>
                  Add Roof Color
                </button>
              </div>

              <div className="col-md-4">
                <AllSidingColors
                  setSelectedSidingColor={setSelectedSidingColor}
                  selectedSidingColor={selectedSidingColor}
                  sidingColors={settings?.sidingColors}
                  settings={settings}
                  updateSettings={updateSettings}
                />
                <button className="btn btn-prime" onClick={handleAddSidingColor}>
                  Add Siding Color
                </button>
              </div>

              <div className="col-md-4">
                <AllTrimColors
                  setSelectedTrimColor={setSelectedTrimColor}
                  selectedTrimColor={selectedTrimColor}
                  trimColors={settings?.trimColors}
                />
                <button className="btn btn-prime" onClick={handleAddTrimColor}>
                  Add Trim Color
                </button>
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

export default Colors;
