import React, { Fragment, useEffect, useState, useContext } from "react";
import ComponentCategoryContext from "../../../../../../context/componentCategory/componentCategoryContext";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";

import "antd/dist/antd.css";
import { Table } from "antd";

const ShopAllComponentCategories = () => {
  const navigate = useNavigate();
  const componentCategoryContext = useContext(ComponentCategoryContext);
  const {
    getComponentCategorys,
    componentCategorys,
    currentComponentCategory,
    loadingComponentCategory,
    setCurrentComponentCategory,
    // clearCurrentComponent,
    updateAllComponentCategories,
    deleteComponentCategory,
  } = componentCategoryContext;

  useEffect(() => {
    // clearCurrentComponent();
    getComponentCategorys();
  }, []);

  useEffect(() => {}, [componentCategorys]);

  const currentPath = window.location.pathname;

  let filteredCategories = [];

  if (currentPath.endsWith("building")) {
    filteredCategories = componentCategorys?.filter((category) => category.metalBuilding);
  } else if (currentPath.endsWith("roof")) {
    filteredCategories = componentCategorys?.filter((category) => !category.metalBuilding);
  } else {
    filteredCategories = componentCategorys;
  }

  const columns = [
    {
      title: "Name",
      width: "20%",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },
    {
      title: "ID",
      width: "20%",
      render: (record) => <Fragment>{record._id}</Fragment>,
    },

    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          {componentCategorys && (
            <Fragment>
              <button
                className="btn btn-xs btn-outline-primary"
                type="button"
                onClick={(event) => {
                  event.stopPropagation(); // Stop event propagation
                  handleViewComponentCategory(record);
                }}
              >
                View
              </button>

              {/* <button
                className="btn btn-xs btn-outline-primary"
                type="button"
                onClick={(event) => {
                  event.stopPropagation(); // Stop event propagation
                  handleDeleteComponentCategory(record);
                }}
              >
                Delete
              </button> */}
            </Fragment>
          )}
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewComponentCategory = (componentCategory) => {
    setCurrentComponentCategory(componentCategory);
    navigate("/shop/metalpackage/category/form");
  };

  const handleDeleteComponentCategory = (record) => {
    deleteComponentCategory(record._id);
  };

  const handleRowClick = (record, rowIndex) => {
    // console.log(`Clicked row with index: ${rowIndex} and record:`, record);
    setCurrentComponentCategory(record);
    navigate("/shop/metalpackage/all");
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleAddCategory = () => {
    navigate("/shop/metalpackage/category/form");
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <button className="mb-2 btn btn-prime float-start" onClick={() => handleAddCategory()}>
            Add Category
          </button>
          <button className="mb-2 btn btn-outline-danger float-end" onClick={() => handleBack()}>
            Back
          </button>
        </div>
      </div>
      <Table
        rowClassName={() => "hover-row"}
        loading={componentCategorys ? false : true}
        pagination={false}
        columns={columns}
        dataSource={filteredCategories && filteredCategories}
        // dataSource={testModels}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => handleRowClick(record, rowIndex), // click row
          };
        }}
      />
    </Fragment>
  );
};

export default ShopAllComponentCategories;
