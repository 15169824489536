import React, { useContext, useEffect, useState, Fragment } from "react";
import { Card, Typography, Table } from "antd";
import { useNavigate } from "react-router-dom";
import MetalOrderContext from "../../../context/metalOrder/metalOrderContext";
import Select from "react-select";
import AuthContext from "../../../context/auth/authContext";
const { Title } = Typography;

const ShopHome = () => {
  const navigate = useNavigate();
  const metalOrderContext = useContext(MetalOrderContext);
  const { getMetalOrders, metalOrders, setCurrentMetalOrder, clearCurrentMetalOrder, clearMetalOrders, updateMetalOrder } =
    metalOrderContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const statusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Confirmed", label: "Confirmed" },
    { value: "Recieved", label: "Recieved" },
  ];

  const handleStatusChange = (record, e) => {
    let newRecord = { ...record, status: e.value };
    updateMetalOrder(newRecord);
  };

  useEffect(() => {
    getMetalOrders();
    // on unload clear the metal orders
    return () => {
      clearMetalOrders();
    };
  }, []);

  const columns = [
    {
      title: "PO#",
      dataIndex: "purchaseOrderNumber",
      render: (text, record, index) => (
        <span onClick={() => handleRowClick(record, index)} style={{ cursor: "pointer", display: "block", height: "100%" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (value, row, index) => {
        return <span>{new Date(value).toLocaleDateString()}</span>;
      },
    },
    {
      title: "Status",
      width: "20%",
      render: (text, record) => (
        <Fragment>
          <Select
            className="basic-single"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            value={(statusOptions && statusOptions.find((option) => option.value === record.status)) || null}
            name="status"
            options={statusOptions}
            onChange={(e) => handleStatusChange(record, e)}
          />
        </Fragment>
      ),
    },
  ];

  const handleViewMetalOrder = (metalOrder) => {
    setCurrentMetalOrder(metalOrder);
    navigate(`/shop/purchase/metal/form`);
  };

  const handleAddNewMetalOrder = (e) => {
    e.preventDefault();
    clearCurrentMetalOrder();
    navigate("/shop/purchase/metal/form");
  };

  const handleRowClick = (record, rowIndex) => {
    // console.log(`Clicked row with index: ${rowIndex} and record:`, record);
    setCurrentMetalOrder(record);
    navigate(`/shop/purchase/metal/form`);
  };

  const handleViewAllOrders = (e) => {
    e.preventDefault();
    navigate("/shop/purchase/metal");
  };

  const [fontSize, setFontSize] = useState("2em");

  const updateFontSize = () => {
    if (window.innerWidth < 1100) {
      setFontSize(".8em");
    } else if (window.innerWidth < 1500) {
      setFontSize("1.2em");
    } else {
      setFontSize("2em");
    }
  };

  useEffect(() => {
    updateFontSize(); // Set initial font size
    window.addEventListener("resize", updateFontSize); // Update font size when window is resized

    // Cleanup: remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateFontSize);
    };
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "50vh",
          marginTop: "5%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "65%", // Assuming both buttons combined take up 50% of the container width
          }}
        >
          <button
            type="button"
            className="btn"
            onClick={handleAddNewMetalOrder}
            style={{ textDecoration: "none", width: "40%" }} // 2% less to allow for some space between buttons
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 15,
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
                backgroundColor: "#ed1c24",
                color: "white",
                fontSize: fontSize,
                // padding: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "50px",
                paddingBottom: "50px",
                textAlign: "center",
              }}
            >
              Create New Order
            </div>
          </button>

          <button type="button" className="btn" onClick={handleViewAllOrders} style={{ textDecoration: "none", width: "40%" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 15,
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
                backgroundColor: "#005087",
                color: "white",
                fontSize: fontSize,
                // padding: "50px",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "50px",
                paddingBottom: "50px",
                textAlign: "center",
              }}
            >
              View All Orders
            </div>
          </button>
        </div>

        <div
          style={{
            width: "65%",
            height: "50%",
            textAlign: "center",
            marginTop: "5%",
          }}
        >
          <Title level={1}>Recent Orders</Title>

          <Table
            rowClassName={() => "hover-row"}
            loading={metalOrders ? false : true}
            pagination={false}
            columns={columns}
            dataSource={metalOrders ? metalOrders.slice(0, 5) : []}
            rowKey="_id"
            // instead of the whole row being clickable, i only want the PO# column to be clickable

            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: () => handleRowClick(record, rowIndex), // click row
            //   };
            // }}
            // onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ShopHome;
