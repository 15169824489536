import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ItemContext from "../../../../../context/item/itemContext";
import ItemCategoryContext from "../../../../../context/itemCategory/itemCategoryContext";
import { Table } from "antd";
import ItemFilter from "../../../../../context/item/ItemFilter";

const AllItems = () => {
  const navigate = useNavigate();
  const itemContext = useContext(ItemContext);
  const { getItemsByCategory, deleteItem, setCurrentItem, items, filteredItems, loadingItem, clearItems } = itemContext;

  const itemCategoryContext = useContext(ItemCategoryContext);
  const { currentItemCategory } = itemCategoryContext;

  useEffect(() => {
    currentItemCategory && getItemsByCategory(currentItemCategory._id);
    return () => {
      clearItems();
    };
  }, []);

  useEffect(() => {}, [items]);

  const handleRemove = (item) => {
    let answer = window.confirm(`Are you sure you want to delete ${item.value.name}?`);
    if (answer) {
      deleteItem(item);
    }
  };

  const handleViewProduct = (item) => {
    setCurrentItem(item);
    navigate(`/shop/items/update`);
  };

  const handleRowClick = (record, rowIndex) => {
    // console.log(`Clicked row with index: ${rowIndex} and record:`, record);
    setCurrentItem(record);
    navigate(`/shop/items/update`);
  };

  const handleAddItem = () => {
    navigate(`/shop/items/add`);
  };

  const columns = [
    {
      title: "Name",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },
    {
      title: "Product Code",
      render: (record) => <Fragment>{record.strProductID}</Fragment>,
    },

    // {
    //   title: "Edit",
    //   width: "5%",
    //   render: (text, record) => (
    //     <span>
    //       {
    //         <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewProduct(record)}>
    //           View
    //         </button>
    //       }
    //     </span>
    //   ),
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleBack = () => {
    clearItems();
    navigate(-1);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button className="mb-2 btn btn-prime" onClick={() => handleAddItem()}>
                  Add Item
                </button>
                <button className=" ml-2 mb-2 btn btn-outline-danger float-end" onClick={() => handleBack()}>
                  Back
                </button>
              </div>
            </div>

            <ItemFilter />

            <Table
              rowClassName={() => "hover-row"}
              pagination={false}
              loading={!loadingItem && items ? false : true}
              columns={columns}
              dataSource={filteredItems && filteredItems !== null ? filteredItems : items && items}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => handleRowClick(record, rowIndex), // click row
                };
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllItems;
