import React, { Fragment, useContext, useEffect, useState } from "react";
import AlertContext from "../../context/alert/alertContext";

import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";

const Alerts = () => {
  const [counter, setCounter] = useState(0);
  const alertContext = useContext(AlertContext);
  const { alerts, removeAlert } = alertContext;

  const handleRemoveAlert = (id) => {
    removeAlert(id);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter(counter + 1);
    }, 3600000);

    return () => clearInterval(intervalId);
  }, [counter]);

  return (
    <Fragment>
      {alerts &&
        alerts.length > 0 &&
        alerts.map((alert) => (
          <div key={alert.id} className={`alert alert-${alert.type}`}>
            <div className="row">
              <div className="col-md-1">
                <InfoCircleOutlined fontSize="medium" />
              </div>
              <div className="col-md-8 myFont">{alert.msg}</div>

              <div className="col-md-3">
                <CloseOutlined onClick={() => handleRemoveAlert(alert.id)} className="float-end pointer" />
              </div>
            </div>
          </div>
        ))}
    </Fragment>
  );
};

export default Alerts;
