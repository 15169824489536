import React, { Fragment, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MetalOrderContext from "../../../../../context/metalOrder/metalOrderContext";
import { Table, Modal } from "antd";
import Select from "react-select";
import AuthContext from "../../../../../context/auth/authContext";

const ConfirmedOrders = ({ metalOrders }) => {
  const navigate = useNavigate();
  const metalOrderContext = useContext(MetalOrderContext);
  const { deleteMetalOrder, setCurrentMetalOrder, updateMetalOrder, clearCurrentMetalOrder, loadingMetalOrder } =
    metalOrderContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  let filteredMetalOrders = metalOrders?.filter((metalOrder) => metalOrder.status === "Confirmed");

  const handleRemoveMetalOrder = (metalOrder) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Metal Order?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteMetalOrder(metalOrder);
        // clearMetalOrders();
      },
    });
  };

  const handleViewMetalOrder = (metalOrder) => {
    setCurrentMetalOrder(metalOrder);
    navigate(`/shop/purchase/metal/form`);
  };

  const onOrderChange = (e, record) => {
    let checked = e.target.checked;
    if (checked === true) {
      let TheRecievedDate = new Date();
      let newRecord = { ...record, recieved: checked, recievedDate: TheRecievedDate };
      updateMetalOrder(newRecord);
    } else {
      let TheRecievedDate = null;
      let newRecord = { ...record, recieved: checked, recievedDate: TheRecievedDate };
      updateMetalOrder(newRecord);
    }
  };

  const statusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Confirmed", label: "Confirmed" },
    { value: "Recieved", label: "Recieved" },
  ];

  const handleStatusChange = (record, e) => {
    let newRecord = { ...record, status: e.value };
    updateMetalOrder(newRecord);
  };

  const columns = [
    {
      title: "PO#",
      dataIndex: "purchaseOrderNumber",
    },
    ...(user.firstChoiceCustomer
      ? [
          {
            title: "Order #",
            dataIndex: "strOrderNumber",
          },
        ]
      : []),
    {
      title: "Created Date",
      dataIndex: "createdAt",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (value, row, index) => {
        return <span>{new Date(value).toLocaleDateString()}</span>;
      },
    },
    // {
    //   title: "Recieved Date",
    //   dataIndex: "recievedDate",
    //   render: (value, row, index) => {
    //     return <span>{value && new Date(value).toLocaleDateString()}</span>;
    //   },
    // },
    // {
    //   title: "Recieved",
    //   render: (text, record) => <span>{<Checkbox checked={record.recieved} onChange={(e) => onOrderChange(e, record)} />}</span>,
    // },
    {
      title: "Status",
      width: "15%",
      render: (text, record) => (
        <Fragment>
          <Select
            className="basic-single"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            value={(statusOptions && statusOptions.find((option) => option.value === record.status)) || null}
            name="status"
            options={statusOptions}
            onChange={(e) => handleStatusChange(record, e)}
          />
        </Fragment>
      ),
    },
    {
      title: "Actions",
      dataIndex: "slug",
      width: "10%",
      key: "slug",
      render: (text, record) => (
        <Fragment>
          <button
            className="btn btn-xs btn-outline-danger float-start"
            type="button"
            onClick={(e) => handleRemoveMetalOrder(record, e)}
          >
            Delete
          </button>
          {/* <Link to={`/user/metal/orders/order/${record}`}><DownCircleOutlined className='text-primary pl-2'/></Link> */}
          <button
            className="btn btn-xs btn-outline-primary float-end"
            type="button"
            onClick={(e) => handleViewMetalOrder(record, e)}
          >
            View
          </button>
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddNewMetalOrder = (e) => {
    clearCurrentMetalOrder();
    e.preventDefault();
    navigate("/shop/purchase/metal/form");
  };

  const handleExit = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  return (
    <Fragment>
      {/* <div className="row">
            <div className="col-md-12">
            </div>
          </div> */}
      {/* 
          <div className="row">
            <div className="col-md-12">
              

              <button className="btn btn-prime float-start mb-4" type="button" onClick={(e) => handleAddNewMetalOrder(e)}>
                Create New Order
              </button>

              <button className="btn btn-outline-danger float-end mb-4" type="button" onClick={(e) => handleExit(e)}>
                Back
              </button>
            </div>
          </div> */}

      <div className="row">
        <div className="col-md-12">
          <Table
            rowClassName={() => "hover-row"}
            loading={loadingMetalOrder || !filteredMetalOrders ? true : false}
            pagination={{ pageSize: 40 }}
            columns={columns}
            dataSource={filteredMetalOrders}
            rowKey="_id"
            onChange={onChange}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ConfirmedOrders;
