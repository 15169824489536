import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../../context/auth/authContext";
import { Menu } from "antd";

const ShopNav = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const [mode, setMode] = React.useState("inline");
  const [theme, setTheme] = React.useState("light");
  const [current, setCurrent] = useState();
  const { SubMenu } = Menu;
  const handleMenuChange = (e) => {
    setCurrent(e.key);
  };

  return (
    user && (
      <>
        <Menu
          style={{ width: 200, backgroundColor: "#EBEBEB" }}
          onClick={handleMenuChange}
          className="site-layout-background"
          mode={mode}
          selectedKeys={[current]}
          theme={theme}
        >
          {/* <Menu.Item key="19">
            <Link to="/shop/announcements">Announcements</Link>
          </Menu.Item> */}
          <SubMenu key="sub33333" title="Config">
            <Menu.Item key="17787">
              <Link to="/shop/colors">Colors</Link>
            </Menu.Item>
            <Menu.Item key="17">
              <Link to="/shop/items/category/all">Items</Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/shop/categories/roof">Roof Packages</Link>
            </Menu.Item>
            <Menu.Item key="5787">
              <Link to="/shop/categories/building">Building Packages</Link>
            </Menu.Item>
            {/* <Menu.Item key="10">
              <Link to="/shop/addon/category/all">Add-Ons</Link>
            </Menu.Item> */}
          </SubMenu>

          <Menu.Item key="13314">
            <Link to="/shop/purchase/metal">Orders</Link>
          </Menu.Item>
        </Menu>
      </>
    )
  );
};

const CompanyNav = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const [mode, setMode] = React.useState("inline");
  const [theme, setTheme] = React.useState("light");
  const [current, setCurrent] = useState();
  const { SubMenu } = Menu;
  const handleMenuChange = (e) => {
    setCurrent(e.key);
  };

  return (
    user && (
      <>
        <Menu
          style={{ width: 200 }}
          onClick={handleMenuChange}
          className="site-layout-background"
          mode={mode}
          selectedKeys={[current]}
          theme={theme}
        >
          <SubMenu key="sub323" title="Config">
            <Menu.Item key="190">
              <Link to="/company/shops">Shops</Link>
            </Menu.Item>

            <Menu.Item key="17">
              <Link to="/company/items/shops">Standard Items</Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/company/metal/categories">Metal Packages</Link>
            </Menu.Item>
            {/* <Menu.Item key="10">
              <Link to="/company/addon/category/shops">Add-Ons</Link>
            </Menu.Item> */}
          </SubMenu>
        </Menu>
      </>
    )
  );
};

const UserNav = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  if (!user) {
    return null;
  }

  return <>{user.role === "Shop Admin" ? <ShopNav /> : <CompanyNav />}</>;
};

export default UserNav;
