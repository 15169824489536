import React, { Fragment, useEffect, useState, useContext } from "react";
import AuthContext from "../../../context/auth/authContext";
import AllUsers from "./users/AllUsers";
import AddUser from "./users/AddUser";
import { Button, Modal } from "antd";

const AdminDashboard = () => {
  const authContext = useContext(AuthContext);
  // const { loadUser, users, loadAllUsers, copyEverything } = authContext;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    // loadAllUsers();
    // loadUser();
  }, []);
  const handleAddUser = () => {
    showModal();
  };

  return (
    <div className="container">
      <AllUsers />

      <Button type="primary" onClick={handleAddUser}>
        Add User
      </Button>

      <Modal
        // title="Add User"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null} // Remove the default footer buttons
        bodyStyle={{
          maxHeight: "50vh", // Adjust the height based on your needs
          overflowY: "auto",
        }}
      >
        <AddUser />
      </Modal>
    </div>
  );
};

export default AdminDashboard;
