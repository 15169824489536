import React from "react";
import { Page, Document, Image, StyleSheet } from "@react-pdf/renderer";
import InvoiceTitle from "./InvoiceTitle";
import BillTo from "./BillTo";
import InvoiceItemsTable from "./InvoiceItemsTable";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 15,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const Invoice = ({ data, user }) => {
  console.log("data", data);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <InvoiceTitle title="Purchase Order" />

        <BillTo values={data} user={user} />
        <InvoiceItemsTable values={data} />
      </Page>
    </Document>
  );
};

export default Invoice;
