import React, { useContext, useEffect } from "react";
import MyLayout from "./MyLayout";

import { Routes, Route } from "react-router-dom";
import CompanyHome from "./components/pages/company/CompanyHome";
import Login from "./components/auth/Login";
import ProtectedRoutes from "./utils/RequireAuth";

import Settings from "./components/pages/company/settings/Settings";

import PermissionDenied from "./components/layout/PermissionDenied";

import ComponentCategoryForm from "./components/pages/shop/config/components/componentCategory/ComponentCategoryForm";

// shop routes
import ShopSettings from "./components/pages/shop/settings/ShopSettings";
import ShopHome from "./components/pages/shop/ShopHome";

import ShopAllComponents from "./components/pages/shop/config/components/component/ShopAllComponents";
import ShopComponentForm from "./components/pages/shop/config/components/component/ShopComponentForm";
import ShopAllComponentCategories from "./components/pages/shop/config/components/componentCategory/ShopAllComponentCategories";

import MetalPurchaseOrders from "./components/pages/shop/purchase/metalPurchase/MetalPurchaseOrders";

import RHFMetalOrderForm from "./components/pages/shop/purchase/metalPurchase/RHFMetalOrderForm";

import StorMorAnnouncements from "./components/pages/company/announcements/StorMorAnnouncements";
import Announcements from "./components/pages/company/announcements/Announcements";
import ShopAnnouncements from "./components/pages/shop/announcements/ShopAnnouncements";

import AllItemCategories from "./components/pages/shop/config/items/itemCategory/AllItemCategories";
import ItemCategoryForm from "./components/pages/shop/config/items/itemCategory/ItemCategoryForm";
import AllItems from "./components/pages/shop/config/items/AllItems";
import ItemCreate from "./components/pages/shop/config/items/ItemCreate";
import ItemForm from "./components/pages/shop/config/items/ItemForm";

import Colors from "./components/pages/shop/config/colors/Colors";

import AdminDashboard from "./components/pages/admin/AdminDashboard";
import axios from "axios";
import AuthContext from "./context/auth/authContext";

const App = () => {
  const authContext = useContext(AuthContext);
  const { logout } = authContext;

  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with the response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response.status === 401) {
          logout(); // Calling the logout function from your authContext
        }
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/denied" element={<PermissionDenied />} />

      <Route element={<ProtectedRoutes roleRequired="Office Admin" />}>
        <Route path="/company" element={<CompanyHome />} />
        <Route path="/company/home" element={<CompanyHome />} />
        {/* <Route path="/company/shops" element={<AllShops />} />
          <Route path="/company/shop/form" element={<AddShop />} /> */}
        {/* <Route path="/company/items/shops" element={<ItemAllShops />} /> */}
        {/* <Route path="/company/items/shop/all" element={<AllItemCategories />} />
          <Route path="/company/items/category/form" element={<ItemCategoryForm />} />
          <Route path="/company/items/add" element={<ItemCreate />} />
          <Route path="/company/items/update" element={<ItemForm />} /> */}
        <Route path="/company/settings" element={<Settings />} />

        <Route path="/company/announcements/shop" element={<Announcements />} />
        <Route path="/company/announcements/company" element={<StorMorAnnouncements />} />
      </Route>

      <Route element={<ProtectedRoutes roleRequired="Shop Admin" />}>
        <Route path="/" element={<MyLayout />}>
          <Route index element={<ShopHome />} />
          <Route path="/shop" element={<ShopHome />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/shop/home" element={<ShopHome />} />

          <Route path="/shop/settings" element={<ShopSettings />} />
          <Route path="/shop/items/category/all" element={<AllItemCategories />} />
          <Route path="/shop/items/category/form" element={<ItemCategoryForm />} />
          <Route path="/shop/items/add" element={<ItemCreate />} />
          <Route path="/shop/items/all" element={<AllItems />} />
          <Route path="/shop/items/update" element={<ItemForm />} />

          <Route path="/shop/metalpackage/category/form" element={<ComponentCategoryForm />} />
          <Route path="/shop/metalpackage/form" element={<ShopComponentForm />} />
          <Route path="/shop/metalpackage/all" element={<ShopAllComponents />} />
          <Route path="/shop/categories/roof" element={<ShopAllComponentCategories />} />
          <Route path="/shop/categories/building" element={<ShopAllComponentCategories />} />
          <Route path="/shop/purchase/metal" element={<MetalPurchaseOrders />} />
          <Route path="/shop/purchase/metal/form" element={<RHFMetalOrderForm />} />
          <Route path="/shop/announcements" element={<ShopAnnouncements />} />
          <Route path="/shop/colors" element={<Colors />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
