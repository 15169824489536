import React, { useEffect, useContext, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import AuthContext from "../../../../context/auth/authContext";
import { Modal, Button } from "antd";
import AddUser from "./users/AddUser";
import AllUsers from "./users/AllUsers";
import AllRoofColors from "./roofColors/AllRoofColors";
import AddRoofColor from "./roofColors/AddRoofColor";
import AllSidingColors from "./sidingColors/AllSidingColors";
import AddSidingColor from "./sidingColors/AddSidingColor";
import AllTrimColors from "./trimColors/AllTrimColors";
import AddTrimColor from "./trimColors/AddTrimColor";

const SettingsForm = () => {
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  const { clearCurrentUser, user } = authContext;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRoofModalVisible, setIsRoofModalVisible] = useState(false);
  const [isSidingModalVisible, setIsSidingModalVisible] = useState(false);
  const [isTrimModalVisible, setIsTrimModalVisible] = useState(false);

  const [selectedRoofColor, setSelectedRoofColor] = useState();
  const [selectedSidingColor, setSelectedSidingColor] = useState();
  const [selectedTrimColor, setSelectedTrimColor] = useState();

  // user modal
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // roof modal
  const showRoofModal = () => {
    setIsRoofModalVisible(true);
  };
  const handleRoofOk = () => {
    setIsRoofModalVisible(false);
  };

  const handleRoofCancel = () => {
    setIsRoofModalVisible(false);
  };

  // siding modal
  const showSidingModal = () => {
    setIsSidingModalVisible(true);
  };
  const handleSidingOk = () => {
    setIsSidingModalVisible(false);
  };

  const handleSidingCancel = () => {
    setIsSidingModalVisible(false);
  };

  // trim modal

  const showTrimModal = () => {
    setIsTrimModalVisible(true);
  };
  const handleTrimOk = () => {
    setIsTrimModalVisible(false);
  };

  const handleTrimCancel = () => {
    setIsTrimModalVisible(false);
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      shopsRetailPercent: "",
      overheadPercent: "",
    },
  });

  // const onSubmit = (data) => {
  //   // console.log(data);
  //   // if (currentSettings) {
  //   //   reset(currentSettings);
  //   updateCompany(company._id, data);
  //   // } else {
  //   //   addSettings(data);
  //   // }
  // };

  // useEffect(() => {
  //   company && reset(company);
  // }, [company]);

  const handleAddUser = () => {
    clearCurrentUser();
    showModal();
  };

  const handleAddRoofColor = () => {
    showRoofModal();
  };

  const handleAddSidingColor = () => {
    showSidingModal();
  };

  const handleAddTrimColor = () => {
    showTrimModal();
  };

  const handlePriceChange = () => {
    navigate("/company/price/change");
  };

  return (
    <Fragment>
      {/* <div className="container">
        <div className="row mb-4">
          <div className="col-md-12">
            <h2 className="settingsTitle text-center">Settings</h2>
            <button className="btn btn-primary float-end" type="button" onClick={() => handlePriceChange()}>
              Retail Price Change
            </button>
          </div>
        </div>

        <form className="settingsForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mt-2">
                  <label className="settingsLabel" htmlFor="overheadPercentCompany">
                    Overhead Percent
                  </label>
                  <input
                    className="form-control "
                    type="number"
                    step={".01"}
                    id="overheadPercentCompany"
                    {...register("overheadPercentCompany", { required: true })}
                  />
                  {errors.overheadPercentCompany && <p>This field is required</p>}
                </div>
                <div className="form-group mt-2">
                  <label className="settingsLabel">Standard Overhead Percent For Shop</label>
                  <input
                    className="settingsInput"
                    type="number"
                    step={".01"}
                    {...register("overheadPercentShop", { required: true })}
                  />
                  {errors.overheadPercentCompany && <p>This field is required</p>}
                </div>
                <div className="form-group mt-2">
                  <label className="settingsLabel">Dealer Commission</label>
                  <input
                    className="settingsInput"
                    type="number"
                    step={".01"}
                    {...register("dealerCommissionPercent", { required: true })}
                  />
                  {errors.dealerCommissionPercent && <p>This field is required</p>}
                </div>
                <div className="form-group mt-2">
                  <label className="settingsLabel">Sales Rep Commission</label>
                  <input
                    className="settingsInput"
                    type="number"
                    step={".01"}
                    {...register("salesRepCommissionPercent", { required: true })}
                  />
                  {errors.salesRepCommissionPercent && <p>This field is required</p>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mt-2">
                  <label className="settingsLabel">Lot to Customer %</label>
                  <input
                    className="settingsInput"
                    type="number"
                    step={".01"}
                    {...register("lotToCustomerPercent", { required: true })}
                  />
                  {errors.lotToCustomerPercent && <p>This field is required</p>}
                </div>
                <div className="form-group mt-2">
                  <label className="settingsLabel">14' Escort</label>
                  <input
                    className="settingsInput"
                    type="number"
                    step={".01"}
                    {...register("fourteenWideEscort", { required: true })}
                  />
                  {errors.fourteenWideEscort && <p>This field is required</p>}
                </div>
                <div className="form-group mt-2">
                  <label className="settingsLabel">16' Escort</label>
                  <input
                    className="settingsInput"
                    type="number"
                    step={".01"}
                    {...register("sixteenWideEscort", { required: true })}
                  />
                  {errors.sixteenWideEscort && <p>This field is required</p>}
                </div>
                <div className="form-group mt-4">
                  <button className="btn btn-success float-start" type="submit">
                    Update Company
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="settingsForm">
          {company && (
            <Fragment>
              <Modal
                // title="Add User"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null} // Remove the default footer buttons
                bodyStyle={{
                  maxHeight: "50vh", // Adjust the height based on your needs
                  overflowY: "auto",
                }}
              >
                <AddUser />
              </Modal>

              <Modal
                // title="Add User"
                visible={isRoofModalVisible}
                onOk={handleRoofOk}
                onCancel={handleRoofCancel}
                footer={null} // Remove the default footer buttons
                bodyStyle={{
                  maxHeight: "50vh", // Adjust the height based on your needs
                  overflowY: "auto",
                }}
              >
                <AddRoofColor selectedRoofColor={selectedRoofColor} setSelectedRoofColor={setSelectedRoofColor} />
              </Modal>

              <Modal
                // title="Add User"
                visible={isSidingModalVisible}
                onOk={handleSidingOk}
                onCancel={handleSidingCancel}
                footer={null} // Remove the default footer buttons
                bodyStyle={{
                  maxHeight: "50vh", // Adjust the height based on your needs
                  overflowY: "auto",
                }}
              >
                <AddSidingColor selectedSidingColor={selectedSidingColor} setSelectedSidingColor={setSelectedSidingColor} />
              </Modal>

              <Modal
                // title="Add User"
                visible={isTrimModalVisible}
                onOk={handleTrimOk}
                onCancel={handleTrimCancel}
                footer={null} // Remove the default footer buttons
                bodyStyle={{
                  maxHeight: "50vh", // Adjust the height based on your needs
                  overflowY: "auto",
                }}
              >
                <AddTrimColor selectedTrimColor={selectedTrimColor} setSelectedTrimColor={setSelectedTrimColor} />
              </Modal>

              <AllUsers />
              {user && user.superAdmin ? (
                <button className="btn btn-primary" onClick={handleAddUser}>
                  Add User
                </button>
              ) : null}

              <div className="row mt-4">
                <div className="col-md-4">
                  <AllRoofColors
                    setSelectedRoofColor={setSelectedRoofColor}
                    selectedRoofColor={selectedRoofColor}
                    roofColors={company.roofColors}
                  />
                  <button className="btn btn-primary" onClick={handleAddRoofColor}>
                    Add Roof Color
                  </button>
                </div>

                <div className="col-md-4">
                  <AllSidingColors
                    setSelectedSidingColor={setSelectedSidingColor}
                    selectedSidingColor={selectedSidingColor}
                    sidingColors={company.sidingColors}
                  />
                  <button className="btn btn-primary" onClick={handleAddSidingColor}>
                    Add Siding Color
                  </button>
                </div>

                <div className="col-md-4">
                  <AllTrimColors
                    setSelectedTrimColor={setSelectedTrimColor}
                    selectedTrimColor={selectedTrimColor}
                    trimColors={company.trimColors}
                  />
                  <button className="btn btn-primary" onClick={handleAddTrimColor}>
                    Add Trim Color
                  </button>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div> */}
    </Fragment>
  );
};

export default SettingsForm;
