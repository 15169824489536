import React from "react";
import { useUpdateCheck } from "react-update-notification";

const UpdateApp = () => {
  const { status, reloadPage } = useUpdateCheck({
    type: "interval",
    // interval: 10800000,
    interval: 300000,
  });

  if (status === "checking" || status === "current") {
    // console.log("checking for updates");
    return null;
  }

  return (
    // <div>
    //   <button className="btn btn-success text-center" type="button" onClick={reloadPage}>
    //     (Click Here) to update to the latest Version
    //   </button>
    // </div>
    <div className=" row cardwrapper">
      <div className="row mt-4 mb-4">
        <div className="col-md-1"></div>
        <div className="col-md-10 updateversion">
          <h2 className="pt-4 ps-4">There is a new version of CHOICE-LINK Available!</h2>
          <p className="ps-4">As soon as you can refresh CHOICE-LINK.</p>
          <div className="ps-4 pb-4">
            <button className="btn btn-success" type="button" onClick={reloadPage}>
              CLICK HERE to update to the latest Version
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateApp;
