import React, { useState, useEffect, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import MetalOrderContext from "../../../../../context/metalOrder/metalOrderContext";
import AuthContext from "../../../../../context/auth/authContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import { useForm, Controller } from "react-hook-form";
import PakArray from "./RHFMetalOrderPakArray";
import { Modal } from "antd";
import MetalOrderModal from "./MetalOrderModal";
import ReactSelect from "react-select";
import { Checkbox, Spin } from "antd";
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./pdf/Invoice";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const defaultValues = {
  purchaseOrderNumber: "",
  metalOrder: [
    {
      serialNumber: "",
      category: "",
      title: "",
      variation: { name: { label: "", value: "" } },
      roofColor: {},
      trimColor: {},
      sidingColor: {},
      metalPackage: {
        name: "",

        items: [
          {
            item: {
              name: "",
            },
          },
        ],
      },
    },
  ],
};

// RHFMetalOrderForm is a React component for the form used to create and update metal orders.
const RHFMetalOrderForm = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line
  }, []);

  const metalOrderContext = useContext(MetalOrderContext);
  const {
    updateMetalOrder,
    currentMetalOrder,
    setCurrentMetalOrder,
    addMetalOrder,
    clearCurrentMetalOrder,
    clearMetals,
    loadingMetalOrder,
  } = metalOrderContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const settingsContext = useContext(SettingsContext);
  const { settings, getSettings } = settingsContext;

  const initialValues = currentMetalOrder
    ? {
        ...currentMetalOrder,
        orderedDate: currentMetalOrder.orderedDate ? moment(currentMetalOrder.orderedDate) : null,
      }
    : defaultValues;

  const { control, register, handleSubmit, getValues, formState, setValue, watch, reset } = useForm({
    defaultValues: initialValues,
  });

  const { errors } = formState;

  const [formChanged, setFormChanged] = useState(false);

  const [windowModalVisible, setWindowModalVisible] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);

  const handleWindowModalOk = () => {
    // Save the data
    setWindowModalVisible(false);
  };

  const handleWindowModalCancel = () => {
    // Discard the data
    setWindowModalVisible(false);
  };

  const handleChange = (e) => {
    setFormChanged(true);
    // your form change logic here
    // console.log("setting the form state changed");
  };

  const [confirmLoading, setConfirmLoading] = useState(false);

  // onSubmit is the function that handles the form submission. It either creates a new metal order or updates an existing one.
  const onSubmit = async (data) => {
    // console.log("this is the submit", data);

    Modal.confirm({
      title: "Save this Order?",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      async onOk() {
        if (!currentMetalOrder) {
          try {
            setLoading(true);
            await addMetalOrder(data);
            setLoading(false);
          } catch (err) {
            console.error(err);
            setError(`An error occurred while updating the metal order: ${err.message}`);
          }
        } else {
          try {
            setLoading(true);
            await updateMetalOrder(data);
            setLoading(false);
          } catch (err) {
            console.error(err);
            setError(`An error occurred while adding the metal order: ${err.message}`);
          }
        }
      },
      onCancel() {},
    });
  };

  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  // downloadPdf is the function that generates a PDF for the current metal order.
  const downloadPdf = () => {
    setLoading(true);
    const values = getValues();
    if (!currentMetalOrder) {
      let answer = window.confirm(`Generate PDF?`);
      if (answer) {
        setCurrentMetalOrder(values);
        setReady(true);
        // addMetalOrder(values);
      }
    } else {
      setCurrentMetalOrder(values);
      setReady(true);
      // updateMetalOrder(values);
    }
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(blob);
    });
  };

  const [emailSending, setEmailSending] = useState(false);
  const [error, setError] = useState(null);

  // sendPdfByEmail is the function that sends the generated PDF to the vendor via email.
  const sendPdfByEmail = async (e, pdfBlob) => {
    e.preventDefault();
    setEmailSending(true);
    const base64PDF = await blobToBase64(pdfBlob);
    // Prepare your email data
    const emailData = {
      //... other email parameters
      userName: settings.name,
      vendorName: settings.vendorName,
      emailFrom: settings.email,
      emailTo: settings.vendorEmail,
      purchaseOrderNumber: currentMetalOrder.purchaseOrderNumber,
      attachment: base64PDF,
    };
    // service_1am6s71;
    emailjs.send("service_na4ipgq", "template_03uupxk", emailData, "UCnTfq1BhUxk1M1nn").then(
      (result) => {
        // console.log(result.text);
        toast.success(`Your email was sent successfully`);
        // update the currentMetalOrder with emailed set to true in the data variable
        const data = {
          ...currentMetalOrder,
          emailed: true,
        };

        updateMetalOrder(data);
        setCurrentMetalOrder(data);
        setEmailSending(false);
      },
      (error) => {
        console.log(error.text);
        toast.error(`Email error ${error}`);
        setEmailSending(false);
      }
    );
  };

  // const setVendorChange = (e) => {
  //   setValue(`vendor`, e);
  //   handleChange();
  // };

  // setExit is the function that handles the exit button click. It navigates back and clears the current metal order.
  const setExit = (e) => {
    e.preventDefault();
    navigate(-1);
    clearCurrentMetalOrder();
  };

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    navigate(-1);
    clearCurrentMetalOrder();
  };

  // handleClearCurrentMetalOrder is the function that handles the "Create New Order" button click. It clears the current metal order and resets the form.
  const handleClearCurrentMetalOrder = (e) => {
    e.preventDefault();
    clearCurrentMetalOrder();
    reset(defaultValues);
  };

  useEffect(() => {
    if (pdfBlob === null) {
      setReady(false);
    }
  }, [pdfBlob]);

  // handleUpdateOrder is the function that handles the "Save & Update Order" button click. It updates the current metal order.
  const handleUpdateOrder = (e) => {
    e.preventDefault();
    let data = getValues();
    setCurrentMetalOrder(data);
    updateMetalOrder(data);
  };

  return (
    <div style={{ position: "relative" }}>
      {loadingMetalOrder && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0, 0, 0, 0.3)", // Optional: This will add a semi-transparent background.
            zIndex: 1000,
          }}
        >
          <Spin size="large" />
        </div>
      )}
      <div className="">
        {/* <Modal
          title="Save this Order?"
          visible={windowModalVisible}
          onOk={onSubmit}
          confirmLoading={confirmLoading}
          onCancel={handleWindowModalCancel}
          okText="Save"
          cancelText="Discard"
        >
          <p>Do you want to save the data before leaving?</p>
        </Modal> */}

        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="orderform   border mt-2 " style={{ borderRadius: "5px" }}>
            <div className="row">
              <div className="col-md-2">
                {currentMetalOrder && (
                  <button
                    className="btn btn-prime float-start mb-4"
                    type="button"
                    onClick={(e) => handleClearCurrentMetalOrder(e)}
                  >
                    Create New Order
                  </button>
                )}
              </div>
              <div className="col-md-10">
                <button type="button" className="float-end  btn btn-outline-danger " onClick={(e) => setExit(e)}>
                  Back
                </button>
              </div>
            </div>
            <h2 className="text-center">Metal Order Form</h2>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 mt-4 ">
                      <div className="form-group">
                        <label>Po#</label>
                        <input
                          className="form-control inputbg"
                          name="purchaseOrderNumber"
                          {...register("purchaseOrderNumber", {
                            required: true,
                            // maxLength: {
                            //   value: 30,
                            //   message: "Po# cannot exceed 30 characters",
                            // },
                          })}
                        />
                      </div>
                      {errors.purchaseOrderNumber && <p className="error-message">{errors.purchaseOrderNumber.message}</p>}
                    </div>

                    {/* <div className="col-md-3 mt-4">
                    <div className="form-group">
                      <label className="">Ordered Date</label>
                      <Controller
                        render={({ field, name }) => (
                          <DatePicker format={dateFormat} className="form-control" name={name} {...field} />
                        )}
                        control={control}
                        name={`orderedDate`}
                      />
                    </div>
                  </div> */}

                    <div className="col-md-4"></div>

                    <Fragment>
                      {currentMetalOrder && settings && !loading ? (
                        <div className="col-md-4">
                          <PDFDownloadLink
                            document={<Invoice data={currentMetalOrder} user={settings} />}
                            fileName={`${currentMetalOrder.purchaseOrderNumber} metal_order.pdf`}
                            onContextClick={() => {
                              if (!ready) {
                                downloadPdf();
                              }
                            }}
                          >
                            {({ blob, url, loading, error }) => {
                              if (blob && !pdfBlob) {
                                setTimeout(() => setPdfBlob(blob), 0);
                              }
                              return loading ? (
                                "Generating..."
                              ) : (
                                <button type="button" className="btn btn-info ">
                                  Download PDF
                                </button>
                              );
                            }}
                          </PDFDownloadLink>

                          {/* Conditional rendering for the Send Email button */}
                          {pdfBlob &&
                            !currentMetalOrder.emailed &&
                            !user.firstChoiceCustomer &&
                            // if emailSending show loader
                            (emailSending ? (
                              <button className="btn btn-primary ms-4" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Sending...
                              </button>
                            ) : (
                              <button className="btn btn-primary ms-4" type="button" onClick={(e) => sendPdfByEmail(e, pdfBlob)}>
                                Email Order to Vendor
                              </button>
                            ))}
                          {currentMetalOrder.emailed && !user.firstChoiceCustomer && (
                            <Checkbox className="ms-4" checked={currentMetalOrder.emailed} disabled>
                              Email Sent{" "}
                            </Checkbox>
                          )}
                        </div>
                      ) : (
                        currentMetalOrder &&
                        !user.firstChoiceCustomer && (
                          <div className="col-md-2">
                            <button className="btn btn-outline-primary float-end" disabled>
                              Generating...
                            </button>
                          </div>
                        )
                      )}
                    </Fragment>

                    <div className="col-md-4">
                      {!currentMetalOrder && (
                        <button className="btn btn-prime float-end " style={{ fontSize: "20px", width: "250px" }} type="submit">
                          Save & Create Order
                        </button>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-8"></div>
                      <div className="col-md-4">
                        {currentMetalOrder && !user.firstChoiceCustomer && (
                          <button
                            className="btn btn-prime float-end "
                            style={{ fontSize: "20px", width: "250px" }}
                            type="button"
                            onClick={(e) => handleUpdateOrder(e)}
                          >
                            Save & Update Order
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <PakArray
              {...{
                control,
                watch,
                register,
                defaultValues,
                getValues,
                setValue,
                errors,
                getValues,
                clearMetals,
              }}
            />
          </div>
        </form>
      </div>
      {/* {currentMetalOrder && <MetalOrderModal handleClose={handleClose} handleShow={handleShow} show={show} />} */}
    </div>
  );
};

export default RHFMetalOrderForm;
