import React, { useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
// Import Checkbox or replace with regular input type checkbox if you don't have a custom Checkbox component
import { Checkbox } from "antd";

const AddUser = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { addNewUser, updateUser, currentUser } = authContext;
  const { register, handleSubmit, setValue, control } = useForm();

  useEffect(() => {
    if (currentUser) {
      setValue("name", currentUser.name);
      setValue("email", currentUser.email);
      setValue("password", currentUser.password);
      setValue("role", currentUser.role);
      setValue("firstChoiceCustomer", currentUser.firstChoiceCustomer || false);
      setValue("strWarehouseID", currentUser.strWarehouseID);
      setValue("strDepartmentID", currentUser.strDepartmentID);
    }
  }, [currentUser, setValue]);

  const handleUserSubmit = (formData) => {
    if (currentUser) {
      updateUser(currentUser._id, formData);
    } else {
      addNewUser(formData);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <form onSubmit={handleSubmit(handleUserSubmit)}>
      <h4 style={{ textAlign: "center" }}>User</h4>

      <div className="form-group">
        <label>User Name</label>
        <input type="text" name="name" className="form-control inputbg" {...register("name")} />
      </div>

      <div className="form-group">
        <label>Email</label>
        <input type="text" name="email" className="form-control inputbg" {...register("email")} />
      </div>

      <div className="form-group">
        <label>Password</label>
        <input type="text" name="password" className="form-control inputbg" {...register("password")} />
      </div>

      <div className="form-group">
        <label>Role</label>
        <input type="text" name="role" className="form-control inputbg" {...register("role")} />
      </div>

      <div className="col-md-5 mt-2">
        <div className="form-group">
          <label className="">First Choice Metal Customer</label>
          <Controller
            control={control}
            name="firstChoiceCustomer"
            render={({ field }) => (
              <Checkbox
                // disabled={true}
                onChange={(e) => field.onChange(e.target.checked)}
                checked={field.value}
              ></Checkbox>
            )}
          />
        </div>
      </div>

      <div className="form-group mt-4">
        <label>Warehouse ID</label>
        <input className="form-control inputbg" name="strWarehouseID" {...register("strWarehouseID")} />
      </div>
      <div className="form-group mt-4">
        <label>Department ID</label>
        <input className="form-control inputbg" name="strDepartmentID" {...register("strDepartmentID")} />
      </div>

      <button className="btn btn-outline-info float-end mb-2 mt-4">Save User</button>
    </form>
  );
};

export default AddUser;
