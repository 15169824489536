import React, { useState, useEffect, Fragment, useContext } from "react";
import ItemContext from "../../../../../context/item/itemContext";
import ItemCategoryContext from "../../../../../context/itemCategory/itemCategoryContext";
import { useNavigate } from "react-router-dom";
import ItemCreateForm from "./forms/ItemCreateForm";

const initialState = {
  name: "",
  strProductID: "",
  category: "",
};

const ItemCreate = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);

  const itemContext = useContext(ItemContext);
  const { addItem } = itemContext;

  const itemCategoryContext = useContext(ItemCategoryContext);
  const { currentItemCategory } = itemCategoryContext;

  const handleSubmit = (e) => {
    e.preventDefault();
    const newItem = {
      ...values,
      category: currentItemCategory._id, // Add the currentItemCategory._id as the category
    };
    addItem(newItem);
    setValues(initialState);
    // navigate(-1);
  };

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setValues({ ...values, [e.target.name]: e.target.checked });
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const setCategory = (event) => {
    setValues({ ...values, category: event.value });
  };

  const setExit = () => {
    navigate(-1);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <button className="float-end mt-2 mb-2 btn btn-outline-danger " onClick={() => setExit()}>
            Exit
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <ItemCreateForm
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            setValues={setValues}
            setCategory={setCategory}
            values={values}
          />
        </div>
      </div>
    </div>
  );
};

export default ItemCreate;
