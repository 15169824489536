import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import SettingsForm from "./SettingsForm";

const defaultValues = {
  nonInvAddOns: [],
};

const Settings = () => {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  return <SettingsForm />;
};

export default Settings;
