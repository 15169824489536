import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    display: "flex",
  },
  row: {
    flexDirection: "row",
  },
  boxone: {
    paddingTop: 20,
    order: 1,
    flex: 2,

    fontFamily: "Helvetica",
  },
  boxtwo: {
    paddingTop: 20,
    order: 2,
    flex: 1,
    justifyContent: "flex-end",
  },
  textrow: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: 8,
    marginTop: 2,
  },
  textrowtwo: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: 8,
    marginTop: 2,
    fontFamily: "Helvetica-Bold",
  },
  image: {
    width: "75px",
    height: "75px",
    padding: 5,
  },
});

const BillTo = ({ values, user }) => {
  const theDate = new Date();

  const date = new Date((values && values.createdAt && values.createdAt) || theDate).toLocaleDateString();

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <View style={styles.boxone}>
          <Text style={styles.textrowtwo}>COMPANY</Text>
          <Text style={styles.textrow}>{user.name} </Text>
          <Text style={styles.textrow}>{user.streetAddress} </Text>
          <Text style={styles.textrow}>
            {user.city} {user.state}, {user.zipCode}{" "}
          </Text>
        </View>
        <View style={styles.boxtwo}>
          <Text style={styles.textrowtwo}>VENDOR</Text>
          <Text style={styles.textrow}>{user.vendorName} </Text>
          <Text style={styles.textrow}>{user.vendorSteetAddress}</Text>
          <Text style={styles.textrow}>
            {user.vendorCity} {user.vendorState}, {user.vendorZipCode}
          </Text>
          <Text style={styles.textrow}>{user.vendorEmail}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.boxone}>
          <Text style={styles.textrow}>PO #: {values && values.purchaseOrderNumber && values.purchaseOrderNumber} </Text>
          <Text style={styles.textrow}>Date: {date} </Text>
        </View>
      </View>
    </View>
  );
};

export default BillTo;
