import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";

import AuthState from "./context/auth/AuthState";
import AlertState from "./context/alert/AlertState";
import AnnouncementState from "./context/announcements/AnnouncementsState";
import SettingsState from "./context/settings/SettingsState";

import ComponentCategoryState from "./context/componentCategory/ComponentCategoryState";
import ComponentState from "./context/component/ComponentState";
import MetalOrderState from "./context/metalOrder/MetalOrderState";
import ItemCategoryState from "./context/itemCategory/ItemCategoryState";
import ItemState from "./context/item/ItemState";

import { Routes, Route, BrowserRouter } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthState>
        <AlertState>
          <SettingsState>
            <ComponentCategoryState>
              <ComponentState>
                <MetalOrderState>
                  <AnnouncementState>
                    <ItemCategoryState>
                      <ItemState>
                        <Routes>
                          <Route path="/*" element={<App />} />
                        </Routes>
                      </ItemState>
                    </ItemCategoryState>
                  </AnnouncementState>
                </MetalOrderState>
              </ComponentState>
            </ComponentCategoryState>
          </SettingsState>
        </AlertState>
      </AuthState>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
