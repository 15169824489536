import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SettingsContext from "../../../../../../context/settings/settingsContext";
const initialState = {
  trimColor: { color: "" },
};

const AddTrimColor = ({ selectedTrimColor, setSelectedTrimColor }) => {
  const navigate = useNavigate();

  const [values, setValues] = useState(initialState);
  // destructure
  const { trimColor } = values;
  const settingsContext = useContext(SettingsContext);
  const { settings, getSettings, updateSettings } = settingsContext;

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (selectedTrimColor) {
      setValues({
        trimColor: selectedTrimColor,
      });
    }
  }, [selectedTrimColor]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Make a copy of the settings's trimColors array
    let updatedTrimColors = [...settings.trimColors];
    // If a color is selected, update it
    if (selectedTrimColor) {
      const oldColorId = selectedTrimColor._id; // the old color's _id
      const newColor = values.trimColor?.color; // the new color
      // Find the index of the old color
      let index = updatedTrimColors.findIndex((color) => color._id === oldColorId);
      // Replace the old color with the new color
      if (index !== -1) {
        updatedTrimColors[index].color = newColor;
      }
    } else {
      // If no color is selected, add a new one
      const newColor = values.trimColor?.color; // the new color
      updatedTrimColors.push({ color: newColor });
    }
    // Update the settings
    let updatedSettings = { ...settings, trimColors: updatedTrimColors };
    updateSettings(updatedSettings);
    // Reset the form values
    setSelectedTrimColor(null);
    setValues(initialState);
  };

  const handleChange = (e) => {
    const [field, nestedField, nestedNestedField] = e.target.name.split(".");
    setValues((prevValues) => {
      const newValues = {
        ...prevValues,
        [field]: {
          ...prevValues[field],
          [nestedField]: {
            ...prevValues[field][nestedField],
            [nestedNestedField]: e.target.value,
          },
        },
      };
      return newValues;
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h4 style={{ textAlign: "center" }}>Trim Color</h4>

      <div className="form-group">
        <label>Color</label>
        <input
          type="string"
          name="trimColor.color.name"
          className="form-control inputbg"
          value={trimColor.color?.name}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Code</label>
        <input
          type="string"
          name="trimColor.color.code"
          className="form-control inputbg"
          value={trimColor.color?.code}
          onChange={handleChange}
        />
      </div>
      <button className="btn btn-prime float-end mb-2 mt-4">Save Trim Color</button>
    </form>
  );
};

export default AddTrimColor;
